import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import {
  ApiService,
  CnpdataService,
  HeaderService,
  UserdataService,
  LightboxdataService,
  CommonDataService,
  LanguageService,
  CachingService

} from 'src/app/core/services';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    ApiService,
    CnpdataService,
    HeaderService,
    UserdataService,
    LightboxdataService,
    CommonDataService,
    LanguageService,
    CachingService
  ],
  declarations: []
})
export class CoreModule { }
