import { Injectable } from '@angular/core';
import { certificate, keySize, iterationCount } from '../services/rsa-config';
import * as CryptoJS from 'crypto-js';
import * as forge from 'node-forge';

@Injectable({providedIn: 'root'})
export class SecurityService {
    keySize: number;
    iterationCount: number;

    constructor(){
        this.keySize = keySize / 32;
        this.iterationCount = iterationCount;
    }

    AESencrypt(text: string){
        var iv = CryptoJS.lib.WordArray.random(128/8).toString(CryptoJS.enc.Hex);
        var salt = CryptoJS.lib.WordArray.random(128/8).toString(CryptoJS.enc.Hex);
        var passPhrase = CryptoJS.lib.WordArray.random(128/8).toString(CryptoJS.enc.Hex);
        var ciphertext = this.encryptDataWithPBKDF2(salt, iv, passPhrase, text);

        return JSON.stringify({
            payloadToken: btoa(this.getRSAEncryption(passPhrase)), 
            payloadSalt: btoa(this.getRSAEncryption(salt)),
            payloadVariable: btoa(iv), 
            payloadText: btoa(ciphertext)}
        );
    }

    encryptDataWithPBKDF2(salt:any, iv:any, passPhrase:any, plainText:any) {
        var key = this.generateKey(salt, passPhrase);
        var encrypted = CryptoJS.AES.encrypt(
            plainText,
            key,
            { iv: CryptoJS.enc.Hex.parse(iv) });
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    }

    decryptDataWithPBKDF2(salt:any, iv:any, passPhrase:any, cipherText:any) {
        var key = this.generateKey(salt, passPhrase);
        var cipherParams = CryptoJS.lib.CipherParams.create({
          ciphertext: CryptoJS.enc.Base64.parse(cipherText)
        });
        var decrypted = CryptoJS.AES.decrypt(
            cipherParams,
            key,
            { iv: CryptoJS.enc.Hex.parse(iv) });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    generateKey(salt:any, passPhrase:any) {
        var key = CryptoJS.PBKDF2(
            passPhrase, 
            CryptoJS.enc.Hex.parse(salt),
            { keySize: this.keySize, iterations: this.iterationCount });
        return key;
    }

    getRSAEncryption(textToEcrypt: any) {
        var cert = forge.pki.certificateFromPem(certificate);
        var pkey = cert.publicKey;
        return pkey.encrypt(textToEcrypt, 'RSA-OAEP', {
            md: forge.md.sha1.create(),
            mgf1: {
              md: forge.md.sha1.create()
            }
        });
    }

}