import { Injectable } from '@angular/core';
import { TranslateService, } from '@ngx-translate/core';
import { ngxTranslateCode} from 'src/app/core/models';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
  constructor(private translate: TranslateService) {
    translate.addLangs(ngxTranslateCode.map(lang => lang.language));
  }

  setDefaultLanguages(langCode: string) {
    this.translate.setDefaultLang(langCode);
  }

}
