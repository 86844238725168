import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {SecurityService} from '../services/security.service';
import { AuthToken } from 'src/app/core/models';
import { HeaderService, UserdataService } from 'src/app/core/services';
import { Subscription } from 'rxjs';
@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  request: any;
  headersConfig: any = {};
  subscription: Subscription;
  appToken: AuthToken = new AuthToken();
  requests: Array<HttpRequest<any>> = [];

  constructor(
    private userdataService: UserdataService,
    private securityService: SecurityService,
    private headerService: HeaderService
  ) {
    this.appToken = this.userdataService.getTokenData();
  }

  removeRequest(req: HttpRequest<any>): void {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    setTimeout(() => {
      if (!this.requests.length) {
        // this.headerService.changeLoaderStatus(false);
      }
    }, 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.requests.length) {
      // this.headerService.changeLoaderStatus(true);
    }
    this.requests.push(req);
    this.headersConfig['Content-Type'] = 'application/json';
    this.headersConfig['Cache-Control'] = 'no-cache';
    this.headersConfig['Pragma'] = 'no-cache';
    this.headersConfig['Expires'] = '0';
    this.headersConfig.Accept = 'application/json';

    if (req.url.includes('/invoice/payinfo') || req.url.includes('/getbusinessPromotion')) {
      if (this.appToken.getInvtoken()) {
        this.headersConfig.Authorization = `Bearer ${this.appToken.getInvtoken()}`;
      }
    } else {
      if (this.appToken.getEcomToken()) {
        this.headersConfig.Authorization = `Bearer ${this.appToken.getEcomToken()}`;
      }
    }
    const langCode = this.appToken.getLangCode();
    this.headersConfig['Accept-Language'] = `${langCode}`;
    if (req.url.includes('/invoice/payinfo') || req.url.includes('/getbusinessPromotion')) {
      this.request = req.clone({
        body: this.securityService.AESencrypt(req.body), setHeaders: this.headersConfig});
    } else if (req.url.includes('retryFlag')) {
      this.request = req.clone();
    } else {
      this.request = req.clone({
        body: this.securityService.AESencrypt(JSON.stringify(req.body)), setHeaders: this.headersConfig});
    }

    if (req.url.includes('/fetchMerchantPaymentRoles')) {
      this.headersConfig['Cache-Control'] = 'no-cache';
      this.headersConfig['Pragma'] = 'no-cache';
      this.headersConfig['Expires'] = '0';
    }
    /* if (req.url.includes('fetchRewards')) {
      this.request.body = this.securityService.AESencrypt(JSON.stringify(req.body));
    } else {
      this.request.body = req.body;
    } */

    return Observable.create(observer => {
      const subscription = next.handle(this.request)
        .subscribe(event => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        }, err => {
          this.removeRequest(req);
          observer.error(err);
        }, () => {
          this.removeRequest(req);
          observer.complete();
        });
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
