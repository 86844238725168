export const PageTypes = {
  INV: 'INVOICE',
  LB: 'LIGHTBOX'
};

export const InvoiceTypes = {
  TINY: 'HASHID',
  ID: 'INVID'
};

export const ErrorTypes = {
  NOTFOUND: '404',
  UNKNOWN: '412',
  FAILURE: '500'
};

export interface IPaymentInstrument {
  paymentInstrumentId: string;
  paymentInstrumentName: string;
  disabled: boolean;
}

export class EcomAuthorizationResponse {
  responseCode: string;
  responseMessage: string;
  hierLabelId: any;
  currencyCode: string;
  threeDSecureCheck: string;
  businessId: string;
  paymentInstrumentList: IPaymentInstrument[] = [];
}

export interface IPaymentStatus {
  PENDING: number;
  ISSUED: number;
  PAID: number;
  CANCELLED: number;
  EXPIRED: number;
}

export const PaymentStatus = {
  PENDING: 1,
  ISSUED: 2,
  PAID: 3,
  CANCELLED: 4,
  EXPIRED: 5
};

export const CssLogoLoader = {
  loaderVar: []
};

export interface IPaymode {
  CARDPAYMENT: string;
  BANKPAYMENT: string;
  WALLETPAYMENT: string;

}

export const Paymode = {
  CARDPAYMENT: 'CNP_CREDIT',
  BANKPAYMENT: 'NB',
  WALLETPAYMENT: 'WALLET'

};

export interface EMIPlanBean {
  emiPerMonth: string;
  interestRate: string;
  processingFee: string;
  processingFeeIndicator: 'P' | 'A';
  tenure: number;
  totalAmount: string;
  processorName?: string;
  bankProcessorId: string;
}

export interface IHierarchyData {
  checkSum: string;
  currencyCode: string;
  hierLabelId: string;
  hierarchyCriteria: string;
  paymentChannelId: string;
  hierLabelIdReference: string;
}

export interface ISessionData {
  sessionId: string;
  responseCode: string;
  responeMessage: string;
  orderId: string;
  referenceOrderId: string;
  recurrentToken: string;
}

export interface IPaymentInfo {
  amount: number;
  businessId: string;
  businessName: string;
  currencyCode: string;
  mailId: string;
  mobileNumber: string;
  mobilePreFix: string;
  name: string;
  receiptNo: string;
  userId: string;
  batchName: string;
  id: string;
  hashid: string;
  statusId: number;
  paymentFor: string;
  orderId: string;
  firstName: string;
  lastName: string;
}

export class AuthToken {

  private ecomtoken = '';
  private invtoken = '';
  private langCode = '';
  private retryCount = '';

  constructor() {}

  setEcomToken(ecomtoken: string) {
    this.ecomtoken = ecomtoken;
  }

  getEcomToken() {
    return this.ecomtoken;
  }

  setInvtoken(invtoken: string) {
    this.invtoken = invtoken;
  }

  getInvtoken() {
    return this.invtoken;
  }

  setLangCode(langCode: string) {
    this.langCode = langCode;
  }

  getLangCode() {
    return this.langCode;
  }

  setRetryCount(count: string): void {
    this.retryCount = count;
  }

  getRetryCount(): string {
    return this.retryCount;
  }
}
