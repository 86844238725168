import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';

import {
  fieldnames, IPaymentInfo, PageTypes, AuthToken, ILightBoxData, ResponseCode,
  UserdataService, HeaderService, ITxnData, PaymentRolesService, CnpdataService, LightboxdataService
} from 'src/app/core';
import { HomeComponent } from '../home/home.component';

@Component({
  selector: 'app-transaction-complete',
  templateUrl: './transaction-complete.component.html',
  styleUrls: ['./transaction-complete.component.scss'],
})
export class TransactionCompleteComponent implements OnInit, OnChanges {

  netFinalAmount: number;

  @Input()
  pageType: string;

  @Input()
  initiated = false;

  @Input()
  txnStatus: boolean;

  @Input()
  txnData: ITxnData;

  @Output()
  retryPayment: EventEmitter<boolean> = new EventEmitter<boolean>();

  lightboxInfo: ILightBoxData;
  paymentInfo: IPaymentInfo;
  currency = '';
  amount = '';
  retryCount = 0;

  PageTypes = PageTypes;
  fieldnames = fieldnames;

  appToken = new AuthToken();

  constructor(
    private headerService: HeaderService,
    private userdataService: UserdataService,
    private paymentRoles: PaymentRolesService,
    private cnpdataService: CnpdataService,
    private lightBoxService:LightboxdataService
  ) {
    this.headerService.getHeaderCurrency().subscribe(resp => this.currency = resp);
    this.appToken = this.userdataService.getTokenData();
   
  }

  retry(change: boolean) {
    if (this.pageType === this.PageTypes.LB) {
      this.paymentRoles.getRetryOrderId(
        this.lightboxInfo.callbackurl, this.lightboxInfo.orderId,
        this.lightboxInfo.transactionType, this.amount
      ).subscribe(resp => {
        if (resp.responseCode === ResponseCode.successResponseCode) {
          this.retryCount = this.retryCount - 1;
          this.appToken.setRetryCount(this.retryCount.toString());
          this.userdataService.setTokenData(this.appToken);
          this.lightboxInfo.orderId = resp.orderId;
          this.lightboxInfo.checkSumPayment = resp.checksumpayment;
          this.userdataService.setLightboxInfo(this.lightboxInfo);
          this.retryPayment.emit(change);
        }
      });
    } else {
      this.cnpdataService.getPaymentDatabtBatchId(this.paymentInfo.hashid).subscribe(resp => {
        if (resp.responseCode === ResponseCode.successResponseCode) {
          this.retryCount = this.retryCount - 1;
          this.appToken.setRetryCount(this.retryCount.toString());
          this.userdataService.setTokenData(this.appToken);
          this.paymentInfo.orderId = resp.jsonBody.orderId;
          this.userdataService.setPaymentInfo(this.paymentInfo);
          this.retryPayment.emit(change);
        }
      });
    }
  }

  close(): void {
    const appendString = `responseCode=${this.txnData.responseCode}&` +
    `responseMessage=${this.txnData.responseMessage}&` +
    `orderId=${this.lightboxInfo.orderId}&` +
    `referenceOrderId=${this.txnData.referenceOrderId}&` +
    `recurrentToken=${this.txnData.recurrentToken}`;
    this.headerService.closeModal(this.lightboxInfo.callbackurl, appendString + '&txnStatus=' + this.txnStatus + '&amount=' + this.withoutRewards + '&orderid=' + this.lightboxInfo.orderId + "&lightboxInfo.transactionType=" + fieldnames.saveCard + '&initiated=' + this.initiated + '&preAuthFlag=' + this.fieldnames.YES);
  }

  ngOnChanges(changes: SimpleChanges): void {
    let currentVal = changes.txnStatus.currentValue;
    if (this.txnStatus) {
      this.retryCount = 0;
    }
    else {
      this.retryCount = 2;
    }
  }

  public withRewards :any;
  public withoutRewards :any;
  ngOnInit() {
    if (this.pageType === this.PageTypes.INV) {
      this.paymentInfo = this.userdataService.getPaymentInfo();
      this.lightboxInfo = {} as ILightBoxData;
      this.amount = this.paymentInfo.amount.toString();
    }

    this.lightBoxService.currentAmountRemaining.subscribe((response:any)=>{
      if(response){
        this.withRewards = response;
        this.netFinalAmount = response;
      }
    }); 

    if (this.pageType === this.PageTypes.LB) {
      this.lightboxInfo = this.userdataService.getLightboxInfo();
      this.amount = this.lightboxInfo.transactionAmount;
      this.withoutRewards = this.lightboxInfo.transactionAmount;
    }

    this.retryCount = Number(this.appToken.getRetryCount());
    if (isNaN(this.retryCount)) {
      this.retryCount = 0;
    }

    if (this.txnStatus) {
      this.retryCount = 0;
    }

    if (!this.txnData) {
      this.txnData = {} as ITxnData;
    }
  }
}
