import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InvoiceTypes, ErrorTypes } from 'src/app/core';
import { NotFoundComponent} from 'src/app/not-found/not-found.component';
import { SessionOutComponent } from 'src/app/session-out/session-out.component';
import { PaymentSummaryComponent } from 'src/app/payment-summary/payment-summary.component';
import { HomeComponent } from 'src/app/home/home.component';
import { DemoComponent} from 'src/app/demo/demo.component';

const routes: Routes = [
{ path: 'home', component: HomeComponent },
{ path: 'sessionout', component: SessionOutComponent},
{ path: 'demo-cart', component: DemoComponent },
{ path: 'notfound', component: NotFoundComponent, data: {
  type: ErrorTypes.NOTFOUND
} },
{ path: 'error', component: NotFoundComponent, data: {
  type: ErrorTypes.UNKNOWN
} },
{ path: 'failure', component: NotFoundComponent, data: {
  type: ErrorTypes.FAILURE
} },
{ path: ':hid', component: PaymentSummaryComponent, data: {
  type: InvoiceTypes.TINY
} },
{ path: 'cnp/:invId', component: PaymentSummaryComponent, data: {
  type: InvoiceTypes.ID
} }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
