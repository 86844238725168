import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/core';

@Component({
  selector: 'app-session-out',
  templateUrl: './session-out.component.html',
  styleUrls: ['./session-out.component.scss']
})
export class SessionOutComponent implements OnInit {

  erroMessage: any = '';
  showErrorText: any = '';
  defaultError = true;
  translateText: any;
  constructor(private headerService: HeaderService) {
   }

  ngOnInit() {
  }

}
