import { EMIPlanBean } from './../models/paymentInstrument.model';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { utcTimeZone } from 'src/app/core/utils';
import { fieldnames, IDeleteCardPayload, IDeleteVPAPayload, IFetchCardPayload, IFetchVPAPayload, ILightBoxData, ISplitTxnData } from 'src/app/core/models';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserdataService, HeaderService } from 'src/app/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class LightboxdataService {
  private approvedFinalAmount = new BehaviorSubject('');
  currentAmountRemaining = this.approvedFinalAmount.asObservable();
  fallback: string;

  constructor(
    private apiService: ApiService,
    private userdataService: UserdataService,
    public headerService: HeaderService,
    private http: HttpClient) { }

  getPaymentDatabtBatchId(hashID) {
    return this.apiService.post(environment.api_url + '/invoice/payinfo', hashID)
      .pipe(map(data => data));
  }

  getPaymentDatabByInvId(invID) {
    return this.apiService.post(environment.api_url + '/invoice/paymentgetid', invID)
      .pipe(map(data => data));
  }

  getPreAuthMessage(hierLabelIdReference: string) {
    return this.apiService.post(environment.api_mpg_url + '/getPreAuthSetting', { hierLabelIdReference })
      .pipe(map(data => data));
  }

  getCardList(
    inboundFrom: string, sessionId: string, paymentChannelId: string,
    hierLabelIdReference: string, mobilePrefix: string, mobileNumber: string,
    emailAddress: string, hierarchyCriteria: string, customerCode: string,
    transactionAmount: string, emiCheck: boolean
  ) {
    const req: any = {
      paymentChannelId, hierLabelIdReference,
      sessionId, mobilePrefix, mobileNumber,
      emailAddress, hierarchyCriteria, inboundFrom, customerCode
    };
    if (emiCheck) {
      req.emiCheck = fieldnames.YES;
      req.amount = transactionAmount;
    }
    return this.apiService.post(environment.api_mpg_url + '/fetchCardTokens', req)
      .pipe(map(data => data));
  }

  getCardListForWeb(fetchCardPayload: IFetchCardPayload) {
    const req: any = fetchCardPayload;
    if (fetchCardPayload.emiCheck) {
      req.emiCheck = fieldnames.YES;
      req.amount = fetchCardPayload.transactionAmount;
    }
    return this.apiService.post(environment.api_mpg_url + '/fetchCardTokens', req)
      .pipe(map(data => data));
  }

  getVPAList(fetchVPAPayload: IFetchVPAPayload) {
    const req: any = fetchVPAPayload;
    return this.apiService.post(environment.api_mpg_url + '/fetchVPAs', req)
      .pipe(map(data => data));
  }

  createTokenAndFetchBankList(
    paymentChannelId: any, hierLabelIdReference: any,
    hierarchyCriteria: string, amountVal: string, taxAmount: string, currencyCode: string,
    timezone: string, applicationLabel: string,
    consumerMsisdn: string, customerEmailId: string,
    hierLabelId: any, taxIncluded: string, orderId: string,
    checkSumPayment: string,
    electronicGoodsIndicator: string, splitTxnInfo: ISplitTxnData
  ) {
    let bodyData: any = {
      paymentChannelId, hierLabelIdReference,
      hierarchyCriteria, amount: amountVal, taxAmount, currencyCode,
      timezone, applicationLabel,
      consumerMsisdn, emailAddress: customerEmailId,
      hierLabelId, taxIncluded, orderId,
      checkSumPayment,
      electronicGoodsIndicator
    };
    if (splitTxnInfo) {
      bodyData = {
        ...bodyData,
        loyaltyFlag: fieldnames.YES, loyaltyAmount: splitTxnInfo.worthAmount.toString(), loyaltyPoints: splitTxnInfo.points.toString()
      };
    }

    return this.apiService.post(environment.api_mpg_url + '/createTokenAndFetchBankList', bodyData)
      .pipe(map(data => data));
  }

  initiateBankPayment(transactionId: any, bankCode: string, bankName: string) {
    const bodyData = { transactionId, bankCode, bankName };
    return this.apiService.post(environment.api_mpg_url + '/initiateBankPayment', bodyData)
      .pipe(map(data => data));
  }

  proceedToPay(
    cardNumber: string, cardExpiry: string, cardNameCredit: string,
    cvvCredit: string, saveCard: string, additionalData: string,
    sessionId: any, lbdata: ILightBoxData, sdk: string, emiPayload: { plan: EMIPlanBean, type: string }, splitTxnInfo: ISplitTxnData, loyaltyFlag: string,
    fallback: string
  ) {
    const endPoint = lbdata.transactionType === fieldnames.saveCard ? 'saveCard' : (emiPayload ? 'emiSale' : lbdata.transactionType);
    let body: any = {
      paymentChannelId: lbdata.paymentChannelId, hierLabelIdReference: lbdata.hierLabelIdReference,
      transactionType: lbdata.transactionType, pan: cardNumber,
      cardholderName: cardNameCredit, expiryDate: cardExpiry, cardVerificationValue: cvvCredit,
      transactionQualifier: 'CNP_CREDIT', electronicGoodsIndicator: lbdata.electronicGoodsIndicator,
      orderId: lbdata.orderId, customerCode: lbdata.customerCode,
      checkSumPayment: lbdata.checkSumPayment,
      hierarchyCriteria: lbdata.hierarchyCriteria,
      addCardCheck: saveCard, currencyCode: lbdata.currency, timezone: utcTimeZone(),
      mobilePrefix: lbdata.mobilePrefix, sessionId,
      mobile: lbdata.mobile, email: lbdata.email, fallback: fallback
    };
    if (lbdata.transactionType === fieldnames.saveCard) {
      body.addCardCheck = 'on';
    } else {
      body = {
        ...body,
        transactionAmount: lbdata.transactionAmount, taxAmount: lbdata.taxAmount,
        shippingDate: lbdata.shippingDate, initialAmount: lbdata.initialAmount,
        recurringFlag: lbdata.recurringFlag, freeTrialFlag: lbdata.freeTrialFlag,
        taxIncluded: lbdata.taxIncluded, additionalDetails: additionalData, inboundFrom: sdk,
        mobile: lbdata.mobile, email: lbdata.email,
      };
    }
    if (emiPayload) {
      body = {
        ...body,
        transactionType: 'emi',
        emiPeriod: emiPayload.plan.tenure,
        emiProcessingFees: emiPayload.plan.processingFee,
        emiProcessingFeesIndicator: emiPayload.plan.processingFeeIndicator,
        emiInterestRate: emiPayload.plan.interestRate,
        emiOrderAmount: lbdata.transactionAmount,
        transactionAmount: emiPayload.plan.totalAmount,
      };
    }
    if (splitTxnInfo) {
      body = {
        ...body,
        loyaltyFlag: loyaltyFlag, loyaltyAmount: splitTxnInfo.worthAmount.toString(), loyaltyPoints: splitTxnInfo.points.toString()
      };
    }
    // Add Cache-Control header
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this.apiService.post(environment.api_mpg_url + `/${endPoint}`, body)
      .pipe(map(data => data));
  }

  setpaidStatus(invoiceId: string) {
    return this.apiService.post(environment.api_url + '/invoice/paid/', invoiceId)
      .pipe(map(data => data));
  }

  proceedToPayToken(
    cvvCredit: string, token: string, additionalData: string, sessionId: any, lbdata: ILightBoxData,
    emiPayload: { plan: EMIPlanBean, type: string }, splitTxnInfo: ISplitTxnData, loyaltyFlag: string, fallback: string
  ) {

    const endPoint = emiPayload ? 'emiSale' : lbdata.transactionType;
    let body: any = {
      paymentChannelId: lbdata.paymentChannelId, hierLabelIdReference: lbdata.hierLabelIdReference,
      transactionType: lbdata.transactionType, transactionAmount: lbdata.transactionAmount, pan: token,
      cardVerificationValue: cvvCredit, taxAmount: lbdata.taxAmount, transactionQualifier: 'CNP_CREDIT',
      electronicGoodsIndicator: lbdata.electronicGoodsIndicator, orderId: lbdata.orderId, customerCode: lbdata.customerCode,
      checkSumPayment: lbdata.checkSumPayment,
      shippingDate: lbdata.shippingDate, initialAmount: lbdata.initialAmount,
      hierarchyCriteria: lbdata.hierarchyCriteria, currencyCode: lbdata.currency,
      timezone: utcTimeZone(), recurringFlag: lbdata.recurringFlag, freeTrialFlag: lbdata.freeTrialFlag, mobile: lbdata.mobile,
      email: lbdata.email, mobilePrefix: lbdata.mobilePrefix, taxIncluded: lbdata.taxIncluded,
      sessionId, additionalDetails: additionalData, inboundFrom: 'SDK', fallback: fallback
    };

    if (emiPayload) {
      body = {
        ...body,
        transactionType: 'emi',
        emiPeriod: emiPayload.plan.tenure,
        emiProcessingFees: emiPayload.plan.processingFee,
        emiProcessingFeesIndicator: emiPayload.plan.processingFeeIndicator,
        emiInterestRate: emiPayload.plan.interestRate,
        emiOrderAmount: lbdata.transactionAmount,
        transactionAmount: emiPayload.plan.totalAmount
      };
    }
    if (splitTxnInfo) {
      body = {
        ...body,
        loyaltyFlag: loyaltyFlag, loyaltyAmount: splitTxnInfo.worthAmount.toString(), loyaltyPoints: splitTxnInfo.points.toString()
      };
    }
    // Add Cache-Control header
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this.apiService.post(environment.api_mpg_url + `/${endPoint}`, body)
      .pipe(map(data => data));
  }

  deleteSavedCard(token: string) {
    return this.apiService.post(environment.api_mpg_url + '/deleteCardTokens', {
      token
    })
      .pipe(map(data => data));
  }

  deleteSavedCardForWeb(payload: IDeleteCardPayload) {
    return this.apiService.post(environment.api_mpg_url + '/deleteCardTokens', payload)
      .pipe(map(data => data));
  }

  deleteSavedVPA(payload: IDeleteVPAPayload) {
    return this.apiService.post(environment.api_mpg_url + '/deleteVPAs', payload)
      .pipe(map(data => data));
  }

  otpTransStatus(txnId: any): Observable<any> {
    let url = environment.service_integrator_url + "/txnstatus" + "?transactionId=" + txnId;
    return this.http.get(url).pipe(catchError(e => this.failureFormatErrors(e)));
  }

  otpTransStatusForWeb(transactionId: any, checksum:any) {
    const body = { transactionId, checksum };
    return this.apiService.post(environment.api_mpg_url + '/payments/enquiry', body)
      .pipe(map(data => data));
  }

  updateTransStatus(txnId: any): Observable<any> {
    let updateurl = environment.service_integrator_url + "/updatetxnstatus" + "?transactionId=" + txnId;
    return this.http.post<any[]>(updateurl, txnId).pipe(catchError(e => this.failureFormatErrors(e)));
  }

  setFinalAmount(amount: any) {
    this.approvedFinalAmount.next(amount);
  }

  private formatErrors(error: any) {
    if (error.status === 0 || error.status === 502) {
    }
    return throwError(error.error);
  }

  messageSubject = new Subject();
  senttocomponent(msg) {
    this.messageSubject.next(msg);
  }

  failureFormatErrors(error: any) {
    if (error.name == 'HttpErrorResponse' && error.ok == false && error.statusText == 'Unknown Error' || error.status == 503 || error.status == 504) {
      this.senttocomponent('Yes');
    }
    if (error.status === 500 || error.status === 502 || error.status === 0) {
      setTimeout(() => {
        this.headerService.changeLoaderStatus(false);
      }, 3000);
      this.fallback = 'Y';
    }
    return throwError(error);
  }
} 