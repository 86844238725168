import { Injectable } from '@angular/core';

import {
  AuthToken, IPaymentInfo, ILightBoxData,
  IHierarchyData, ISessionData, IAdditionalDetails, ISplitTxnData
} from 'src/app/core';


@Injectable({
  providedIn: 'root',
})
export class UserdataService {
  private paymentInfo: IPaymentInfo = null;
  private lightboxInfo: ILightBoxData;
  private tokenData: AuthToken;
  private hierarchyData: IHierarchyData = null;
  private sessionData: ISessionData = null;
  private additionalData: IAdditionalDetails = null;
  private splitTxnInfo: ISplitTxnData = null;

  getTokenData(): AuthToken {
    return this.tokenData;
  }

  setTokenData(data: AuthToken) {
    this.tokenData = data;
  }

  getPaymentInfo(): IPaymentInfo {
    return this.paymentInfo;
  }

  setPaymentInfo(info: IPaymentInfo): void {
    this.paymentInfo = info;
  }

  getLightboxInfo(): ILightBoxData {
    return this.lightboxInfo;
  }

  setLightboxInfo(info: ILightBoxData): void {
    this.lightboxInfo = info;
  }

  getHierarchyData(): IHierarchyData {
    return this.hierarchyData;
  }

  setHierarchyData(data: IHierarchyData): void {
    this.hierarchyData = data;
  }

  getSessionData(): ISessionData {
    return this.sessionData;
  }

  setSessionData(data: ISessionData): void {
    this.sessionData = data;
  }

  getAdditionalData(): IAdditionalDetails {
    return this.additionalData;
  }

  setAdditionalData(data: IAdditionalDetails): void {
    this.additionalData = data;
  }

  getSplitTxnInfo(): ISplitTxnData {
    return this.splitTxnInfo;
  }

  setSplitTxnInfo(info: ISplitTxnData): void {
    this.splitTxnInfo = info;
  }
}
