export function utcTimeZone() {
    var rightNow = new Date();
    var currentTime = rightNow.toTimeString();
    var currentTimeSliced = currentTime.slice(0,currentTime.indexOf("(") - 1);
    var currentDay = rightNow.getDate().toString();
    if(currentDay.length === 1)
     currentDay = "0" + currentDay;
      
    var currentDate = rightNow.getFullYear() + "-" + (rightNow.getMonth() + 1) + "-" + currentDay;
    var formattedDateTime = currentDate + " " + currentTimeSliced.slice(0,15) + ":" + currentTimeSliced.slice(-2);
   
    return formattedDateTime.replace("GMT", "UTC");
}