import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/core/services/api.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentRolesService {
  roles: any = null;
  cardDetails: {
    type: string, data: {
      number: string, name: string, month: string, year: string, saved: boolean, bankId: string
    }, index: string
  } = null;
  walletDetails: { type: string, msisdn: string } = null;
  bankDetails: { type: string, code: string } = null;
  retryFactor = false;
  bankList: any = null;
  constructor(
    private apiService: ApiService
  ) { }

  getRoles(): any {
    return this.roles;
  }

  setRoles(roles: any): void {
    this.roles = roles;
  }

  setCardDetails(details:
    {
      type: string, data: {
        number: string, name: string, month: string, year: string, saved: boolean, bankId: string
      }, index: string
    }): void {
    this.cardDetails = details;
  }

  getCardDetails(): {
    type: string, data: {
      number: string, name: string, month: string, year: string, saved: boolean, bankId: string
    }, index: string
  } {
    return this.cardDetails;
  }

  setRetryFactor(factor: boolean): void {
    this.retryFactor = factor;
  }

  getRetryFactor(): boolean {
    return this.retryFactor;
  }

  getWalletDetails(): { type: string, msisdn: string } {
    return this.walletDetails;
  }

  setWalletDetails(details: { type: string, msisdn: string }): void {
    this.walletDetails = details;
  }

  getBankDetails(): { type: string, code: string } {
    return this.bankDetails;
  }

  setBankDetails(details: { type: string, code: string }): void {
    this.bankDetails = details;
  }

  getBankList(): any {
    return this.bankList;
  }

  setBankList(list: any) {
    this.bankList = list;
  }

  fetchAllMerchantPaymentRoles(
    paymentChannelId: string, hierLabelIdReference: string, currencyCode: string, hierarchyCriteria: string
  ) {
    // Add Cache-Control header
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this.apiService.post(environment.api_mpg_url + '/fetchMerchantPaymentRoles', {
      hierarchyCriteria, currencyCode, hierLabelIdReference, paymentChannelId
    })
      .pipe(map(data => data));
  }

  getRetryOrderId(url: string, orderId: string, transactionType: string, amount: any) {
    return this.apiService.get(`${url}?orderId=${orderId}&retryFlag=Y&transactionType=${transactionType}&amount=${amount}`)
      .pipe(map(data => data));
  }
}
