import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class CachingService {
  constructor(private apiService: ApiService) {
  }

  getDefaultLanguage() {
    
    return this.apiService.post(environment.caching_api_url + '/language/appLanguage')
      .pipe(map(data => data));
  }
}
