import { EMIPlanBean } from './../core/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-emi-plans',
  templateUrl: './emi-plans.component.html',
  styleUrls: ['./emi-plans.component.scss']
})
export class EmiPlansComponent implements OnInit {
  @Input()
  list: Array<EMIPlanBean> = [];

  @Input()
  currency: string;

  @Input()
  selectable: boolean;

  @Input()
  type: string;

  selectedPlan: EMIPlanBean = null;

  processingFee = '';
  selectedId: string;

  @Output()
  pay = new EventEmitter<{plan: EMIPlanBean, type: string}>();

  constructor() { }

  ngOnInit() {
  }

  selectPlan(plan: EMIPlanBean, index: number
  ): void {
    if (this.selectable) {
      this.selectedPlan = plan;
      this.selectedId = index.toString();
      if (plan.processingFeeIndicator === 'A') {
        this.processingFee = this.currency + plan.processingFee;
      } else {
        this.processingFee = plan.processingFee + '%';
      }
    }
  }

  payEvent(): void {
    this.pay.emit({plan: this.selectedPlan, type: this.type});
  }

}
