import { Component, ViewEncapsulation, OnInit, Compiler, ViewChild, ElementRef } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Pipe, PipeTransform } from "@angular/core";

import { AuthToken, UserdataService, CachingService, ngxTranslateCode, LanguageService, HeaderService } from 'src/app/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  @ViewChild('loadingModal', null)
  loadingModal: ElementRef;

  modalRef: NgbModalRef = null;

  title = 'payPlusCnpWebapp';
  showCommonHeaderTab = false;
  showCommonFooterTab = false;
  showComponent = false;
  subscription: Subscription;
  appToken: AuthToken;
  lightboxEnable = false;
  lBoxHeaderEnable = true;
  enableCustCss: string;
  showHeader = false;
  showFooter = false;
  showPage = false;
  txnError = false;
  showLoader = false;

  constructor(
    private translate: TranslateService,
    private headerService: HeaderService,
    private userdataService: UserdataService,
    private languageService: LanguageService,
    private cachingService: CachingService,
    private compilerService: Compiler,
    private modalService: NgbModal,
    private locationStrategy: LocationStrategy
  ) {
    this.locationStrategy.onPopState(() => {
      window.location.reload();
    });
    this.compilerService.clearCache();
    this.appToken = new AuthToken();
    this.appToken.setEcomToken(environment.ecomtoken);
    this.appToken.setInvtoken(environment.invtoken);
    if (environment.retryCount) {
      this.appToken.setRetryCount(environment.retryCount);
    }
    this.userdataService.setTokenData(this.appToken);
  }

  startSubscriptions(): void {
    this.headerService.getHeaderStatus().subscribe(status => this.showHeader = status);
    this.headerService.getFooterStatus().subscribe(status => this.showFooter = status);
    this.headerService.getPageStatus().subscribe(status => this.showPage = status);
    this.headerService.getLoaderStatus().subscribe(openClose => {
      if (openClose) {
        if (this.modalRef) {
          return;
        }

        this.modalRef = this.modalService.open(this.loadingModal, {
          backdrop: 'static', keyboard: false, windowClass: 'loading-modal',
          centered: true, backdropClass: 'loading-backdrop', container: '.light-box',
        });
        this.modalRef.result.then();
      } else {
        if (this.modalRef) {
          this.modalRef.close();
          this.modalRef = null;
        }
      }
    });
    this.headerService.changePageStatus(true);
  }

  ngOnInit() {
    this.getappDefaultLang();
  }

  getappDefaultLang() {
    this.cachingService.getDefaultLanguage().subscribe(resp => {
      if (resp.t && resp.t.languageCode) {
        const langcode = resp.t.languageCode;
        this.appToken.setLangCode(langcode);
        this.userdataService.setTokenData(this.appToken);
        this.translate.setDefaultLang(ngxTranslateCode.find(el => el.languageCode === langcode).language);
        this.startSubscriptions();
      } else {
        this.setDefaultLang();
      }
    }, error => {
      console.error(error);
      this.setDefaultLang();
    });
  }

  setDefaultLang(): void {
    if (this.appToken.getLangCode()) {
      this.languageService.setDefaultLanguages(ngxTranslateCode.find(el => el.languageCode === this.appToken.getLangCode())
      .language);
    } else {
      this.appToken.setLangCode('en_US');
      this.userdataService.setTokenData(this.appToken);
      this.translate.setDefaultLang('en');
    }
    this.startSubscriptions();
  }
}


@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const seconds: number = Math.floor(value / 180);
    return (      
      (Math.floor(value - seconds * 180) + " seconds")
    );
  }
}
