import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ILightBoxData, fieldnames } from 'src/app/core/models';
import { ApiService } from 'src/app/core/services/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyService {
  constructor(
    private apiService: ApiService
  ) { }

  fetchLoyaltyPoints(customerId: string, orderId: string) {
    return this.apiService.post(environment.loyalty_url + '/fetchRewards', {
      customerId, orderId, referenceId: orderId
    })
      .pipe(map(data => data));
  }

  loyaltySale(loyaltyPoints: string, loyaltyAmount: string, lightboxInfo: ILightBoxData, additionalDetails: string) {
    const body = {
      ...lightboxInfo,
      currencyCode: lightboxInfo.currency, transactionQualifier: 'CNP_CREDIT',
      loyaltyPoints, loyaltyAmount, loyaltyFlag: fieldnames.YES, additionalDetails
    };
    return this.apiService.post(environment.api_mpg_url + '/loyaltySale', body)
      .pipe(map(data => data));
  }

}
