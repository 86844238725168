import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import {
  UserdataService, ISplitTxnData, fieldnames, HeaderService,
  IPaymentInfo, PageTypes, ILightBoxData, CssLogoLoader
} from 'src/app/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  amountLocal = '';
  currency = '';
  paymentInfo: IPaymentInfo;
  lightboxInfo: ILightBoxData;
  subscription: Subscription;
  userDataStatus = false;
  logoUrl: string;
  fieldnames = fieldnames;
  pageType: string;
  PageTypes = PageTypes;
  splitTxnInfo: ISplitTxnData;
  splitTxnStatus = false;

  constructor(
    private headerService: HeaderService,
    private userdataService: UserdataService
  ) {
    this.headerService.getHeaderAmount().subscribe(amt => this.amountLocal = amt);
    this.headerService.getHeaderCurrency().subscribe(currency => this.currency = currency);
    this.headerService.getSplitTxnStatus().subscribe(status => {
      this.splitTxnStatus = status;
      if (this.splitTxnStatus) {
        this.splitTxnInfo = this.userdataService.getSplitTxnInfo();
      }
    });
    this.pageType = this.headerService.getPageType();
    if (this.pageType === PageTypes.INV) {
      this.paymentInfo = this.userdataService.getPaymentInfo();
    } else {
      this.lightboxInfo = this.userdataService.getLightboxInfo();
    }
    if (this.lightboxInfo && this.lightboxInfo.logoUrl) {
      this.logoUrl = this.lightboxInfo.logoUrl;
    } else {
      if (CssLogoLoader.loaderVar.length > 0 && this.lightboxInfo) {
        const loaderVal =  CssLogoLoader.loaderVar.map(el => this.lightboxInfo[el]);
        const dyfilename = loaderVal.join('');
        this.logoUrl = `/assets/images/${dyfilename}.png`;
      } else {
        this.logoUrl = `/assets/images/app-logo.png`;
      }
    }
  }

  ngOnInit() {
  }

  closeModal() {
    const appendString = 'responseCode=4183&responseMessage=Transaction Terminated';
    this.headerService.closeModal(this.lightboxInfo.callbackurl, appendString);
  }
}
