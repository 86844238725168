import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageTypes } from 'src/app/core/models';

@Injectable()
export class HeaderService {
  private pageType: string;
  private retryUrl: string;

  private showHeader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private showFooter: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private showPage: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private showHeaderAmount: BehaviorSubject<string> = new BehaviorSubject('');
  private showHeaderCurrency: BehaviorSubject<string> = new BehaviorSubject('');
  private showSplitTxnStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private showLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private headerStatus: Observable<boolean>;
  private footerStatus: Observable<boolean>;
  private pageStatus: Observable<boolean>;
  private headerAmount: Observable<string>;
  private headerCurrency: Observable<string>;
  private splitTxnStatus: Observable<boolean>;
  private loaderStatus: Observable<boolean>;

  constructor() {
    this.headerStatus = this.showHeader.asObservable();
    this.footerStatus = this.showFooter.asObservable();
    this.pageStatus = this.showPage.asObservable();
    this.headerAmount = this.showHeaderAmount.asObservable();
    this.headerCurrency = this.showHeaderCurrency.asObservable();
    this.splitTxnStatus = this.showSplitTxnStatus.asObservable();
    this.loaderStatus = this.showLoader.asObservable();
  }

  closeModal(callbackurl: string, appendString: string) {
    if (this.pageType === PageTypes.LB) {
      if (window.parent === window && window.opener) {
        window.opener.location.href = callbackurl +
        `?${appendString}`;
        window.close();
        return;
      }

      if (window.parent) {
        window.parent.location.href = callbackurl +
        `?${appendString}`;
      }
    }
  }

  getPageType(): string {
    return this.pageType;
  }

  setPageType(type: string): void {
    if (type === PageTypes.INV || type === PageTypes.LB) {
      this.pageType = type;
    } else {
      throw Error('Invalid Page Type');
    }
  }

  changeHeaderStatus(status: boolean): void {
    this.showHeader.next(status);
  }

  changeFooterStatus(status: boolean): void {
    this.showFooter.next(status);
  }

  getHeaderStatus(): Observable<boolean> {
    return this.headerStatus;
  }

  getFooterStatus(): Observable<boolean> {
    return this.footerStatus;
  }

  changePageStatus(state: boolean): void {
    this.showPage.next(state);
  }

  getPageStatus(): Observable<boolean> {
    return this.pageStatus;
  }

  changeHeaderAmount(amt: string): void {
    this.showHeaderAmount.next(amt);
  }

  getHeaderAmount(): Observable<string> {
    return this.headerAmount;
  }

  changeHeaderCurrency(currency: string): void {
    this.showHeaderCurrency.next(currency);
  }

  getHeaderCurrency(): Observable<string> {
    return this.headerCurrency;
  }

  changeSplitTxnStatus(status: boolean): void {
    this.showSplitTxnStatus.next(status);
  }

  getSplitTxnStatus(): Observable<boolean> {
    return this.splitTxnStatus;
  }

  changeLoaderStatus(status: boolean): void {
    this.showLoader.next(status);
  }

  getLoaderStatus(): Observable<boolean> {
    return this.loaderStatus;
  }

  setRetryUrl(url: string): void {
    this.retryUrl = url;
  }

  getRetryUrl(): string {
    return this.retryUrl;
  }
}
