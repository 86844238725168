import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  CnpdataService, ResponseCode, IPaymentStatus,
  PaymentStatus, HeaderService, AuthToken, UserdataService, IPaymentInfo, PageTypes, IHierarchyData, paymentChannel, InvoiceTypes
} from 'src/app/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentSummaryComponent implements OnInit {
  PaymentStatus = PaymentStatus;
  invoiceHashId = '';
  invoiceId = '';
  invalidId = false;
  enablePay = false;
  paymentInfo: IPaymentInfo;
  businessPromotion: any = null;
  appToken: AuthToken = new AuthToken();
  paymentStatus: IPaymentStatus = PaymentStatus;
  showDetails = false;
  hierarchyData: IHierarchyData = {} as IHierarchyData;
  paymentChannelIdList: Array<{
    paymentChannel: string,
    paymentChannelId: string
  }> = [];
  constructor(
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private router: Router,
    private userdataService: UserdataService,
    private cnpdataService: CnpdataService
  ) {
    this.appToken.setEcomToken(environment.ecomtoken);
    this.appToken.setInvtoken(environment.invtoken);
    this.userdataService.setTokenData(this.appToken);
    this.headerService.setPageType(PageTypes.INV);
    this.headerService.changeHeaderStatus(false);
    this.headerService.changeFooterStatus(false);
    this.headerService.setRetryUrl(this.router.url);
  }

  ngOnInit() {
    if (this.route.snapshot.data.type === InvoiceTypes.TINY) {
      this.route.url.subscribe(resp => {
        this.invoiceHashId = resp[resp.length - 1].path;
        this.getDetailsByBatchID();
      });
    }

    if (this.route.snapshot.data.type === InvoiceTypes.ID) {
      this.route.url.subscribe(resp => {
        this.invoiceId = resp[resp.length - 1].path;
        this.getPaymentDatabByInvId();
      });
    }
  }

  handleError(error?: any): void {
    console.error(error);
    this.router.navigate(['error']);
  }

  getPaymentDatabByInvId() {
    if (this.invoiceId) {
      this.cnpdataService.getPaymentDatabByInvId(this.invoiceId).subscribe(resp => {
        if (resp.responseCode === ResponseCode.successResponseCode) {
          if (!resp.jsonBody) {
            this.router.navigate(['notfound']);
          } else {
            this.paymentInfo = resp.jsonBody;
            this.paymentInfo.hashid = this.invoiceHashId;
            if (!this.paymentInfo.receiptNo) {
              this.paymentInfo.receiptNo = 'NA';
            }
            if (
              this.paymentInfo.statusId !== this.PaymentStatus.PAID &&
              this.paymentInfo.statusId !== this.PaymentStatus.CANCELLED &&
              this.paymentInfo.statusId !== this.PaymentStatus.EXPIRED
            ) {
              this.enablePay = true;
            }
            if (this.paymentInfo.userId) {
              this.getHierarchyDetails(this.paymentInfo.userId, this.enablePay);
            }
          }
        } else {
          this.router.navigate(['notfound']);
        }
      }, error => this.handleError(error));
    }

  }

  getDetailsByBatchID() {
    if (this.invoiceHashId) {
      this.cnpdataService.getPaymentDatabtBatchId(this.invoiceHashId).subscribe(resp => {
        if (resp.responseCode === ResponseCode.successResponseCode) {
          if (!resp.jsonBody) {
            this.router.navigate(['notfound']);
          } else {
            this.paymentInfo = resp.jsonBody;
            this.paymentInfo.hashid = this.invoiceHashId;
            if (!this.paymentInfo.receiptNo) {
              this.paymentInfo.receiptNo = 'NA';
            }
            if (
              this.paymentInfo.statusId !== this.PaymentStatus.PAID &&
              this.paymentInfo.statusId !== this.PaymentStatus.CANCELLED &&
              this.paymentInfo.statusId !== this.PaymentStatus.EXPIRED
            ) {
              this.enablePay = true;
            }
            if (this.paymentInfo.userId) {
              this.getHierarchyDetails(this.paymentInfo.userId);
            }
          }
        } else {
          this.router.navigate(['notfound']);
        }
      }, error => this.handleError(error));
    }

  }
  getHierarchyDetails(userId: string, redirect?: boolean) {
    this.cnpdataService.fetchHierarchyDetails(userId).subscribe(resp => {
      if (resp.responseCode === ResponseCode.successResponseCode) {
        this.paymentInfo.businessId = resp.businessId;
        this.paymentInfo.businessName = resp.businessName;
        this.userdataService.setPaymentInfo(this.paymentInfo);
        this.showDetails = true;
        if (this.paymentInfo.businessId) {
          this.getbusinessPromotion(this.paymentInfo.businessId);
        }

        this.paymentChannelIdList = resp.paymentChannelIdList;
        const ele = this.paymentChannelIdList.find(pch => pch.paymentChannel === paymentChannel.TYPEWEB);
        if (this.paymentChannelIdList && ele) {
          this.hierarchyData.paymentChannelId = ele.paymentChannelId;
          this.hierarchyData.checkSum = 'e1029617db6a63faeea6c8aed9ad4421baa337f873e53c961f4e4f1a21578cd8';
          this.hierarchyData.currencyCode = this.paymentInfo.currencyCode;
          this.hierarchyData.hierLabelId = resp.hierLabelId;
          this.hierarchyData.hierLabelIdReference = resp.hierLabelIdReference;
          this.hierarchyData.hierarchyCriteria = resp.hierarchyCriteria;
          this.userdataService.setHierarchyData(this.hierarchyData);
          if (redirect) {
            this.proceedToPay();
          }
        } else {
          this.router.navigate(['failure']);
        }
      } else {
        this.router.navigate(['failure']);
      }
    }, error => this.handleError(error));
  }

  proceedToPay() {
    this.router.navigate(['home']);
  }

  getbusinessPromotion(businessId: string) {
    this.cnpdataService.getbusinessPromotion(businessId).subscribe(resp => {
      if (resp.responseCode === ResponseCode.successResponseCode) {
        this.businessPromotion = resp.t;
      }
    }, error => this.handleError(error));
  }

}
