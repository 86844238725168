import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { IEMIEligibilityPayload, IFetchPlansPayload } from '../models';

@Injectable({
  providedIn: 'root'
})
export class EmiPaymentsService {
  private isEmiApplicable: boolean;

  constructor(
    private apiService: ApiService
  ) { }

  setIsEmiApplicable(flag: boolean) {
    this.isEmiApplicable = flag;
  }

  getIsEmiApplicable(): boolean {
    return this.isEmiApplicable;
  }

  fetchEmiApplicability(hierLabelIdReference: string, amount: string, paymentChannelId: string) {
    return this.apiService.post(environment.api_mpg_url + '/checkEmiEligibility', {
      hierLabelIdReference, amount, paymentChannelId
    })
      .pipe(map(data => data));
  }

  fetchBinEligibility(hierLabelIdReference: string, amount: string, paymentChannelId: string, bin: string) {
    return this.apiService.post(environment.api_mpg_url + '/checkBinEmiEligibility', {
      hierLabelIdReference, amount, paymentChannelId, bin
    })
      .pipe(map(data => data));
  }

  fetchAllBankIds(hierLabelIdReference: string, transactionAmount: string, paymentChannelId: string) {
    return this.apiService.post(environment.api_mpg_url + '/fetchEmiBankIds', {
      hierLabelIdReference, transactionAmount, paymentChannelId
    })
      .pipe(map(data => data));
  }

  fetchPlans(hierLabelIdReference: string, transactionAmount: string, paymentChannelId: string, bankId: string) {
    return this.apiService.post(environment.api_mpg_url + '/fetchEmiPlans', {
      hierLabelIdReference, transactionAmount, paymentChannelId, bankId
    })
      .pipe(map(data => data));
  }

  fetchBinEligibilityForWeb(payload: IEMIEligibilityPayload) {
    return this.apiService.post(environment.api_mpg_url + '/checkBinEmiEligibility', payload)
      .pipe(map(data => data));
  }

  fetchPlansForWeb(payload: IFetchPlansPayload) {
    return this.apiService.post(environment.api_mpg_url + '/fetchEmiPlans', payload)
      .pipe(map(data => data));
  }
}
