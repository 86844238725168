import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import shajs from 'sha.js';
import {
  ILightBoxData, IAdditionalDetails, EMIPlanBean, EmiPaymentsService,
  HeaderService, LightboxdataService, UserdataService,
  CnpdataService, fieldnames, PageTypes, IPaymentInfo, CardScreens,
  IHierarchyData, ISessionData, ResponseCode,
  OtpResponseConstants, PaymentRolesService, ISplitTxnData, ITxnData, ResponseContextPath, ICardPayload, IFetchCardPayload, IDeleteCardPayload, IEMIEligibilityPayload, IFetchPlansPayload
} from 'src/app/core';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { element } from 'protractor';
import { type } from 'os';

declare var $: any;

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnInit {

  @Input()
  isEmiScreen = false;

  @Output()
  showHome: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('cardForm', { static: false })
  cardForm: NgForm;

  @ViewChild('cardA', null)
  cardAField: ElementRef;

  @ViewChild('input1', null)
  otp1Field: ElementRef;

  @ViewChild('cvvField', null)
  cvvField: ElementRef;

  fieldNames = fieldnames;
  cardAValue = '';
  cardBValue = '';
  cardCValue = '';
  cardDValue = '';
  monthFieldValue = '';
  yearFieldValue = '';
  cvvFieldValue = '';
  isCollapsed = true;
  currentScreen = '';
  cardNumber = '';
  cardValid = true;
  cardImage = '';
  cardLogo = '';
  cardNumberError = '';
  nameOnCard = '';
  nameValid = true;
  expDateValid = true;
  cvvHelpToolTip = false;
  cvvValid = true;
  addCardCheck: any;
  regexSpecial = /^[e.+-/*]$/;
  regexNum = /^\d$/;
  regexChars = '^[a-zA-Z ]*$';
  isCardExpired = true;
  cardScheme: string;
  cardCountry: string;
  binNumber: string;

  cards: Array<{ cardNumber: string, enabled: boolean, cardScheme?: string, isCardExpired?: boolean }> = [];
  cardTokenList: any[] = [];
  selectedCard = '';
  savedCardCVV = '';
  access_code: any;
  lboxAdditionalDetails: IAdditionalDetails;
  showwallet = true;
  lightBoxData: ILightBoxData;
  isSaveCardsExist = false;
  additionalData: string;
  cardExpiry: any;
  saveCard = 'off';
  cvvCreditSavecard: any;
  token: any;
  preauthShortMsg = '';
  preAuthData = '';
  fallback: string = 'N';
  loyaltyFlag: string = fieldnames.YES;

  otp: any;
  otpVerified = false;
  otpSent = false;
  resentOtp = false;
  exhausted = false;
  invalid = false;
  cvvHelp = false;
  successMessage: any;
  errorMessage: any;
  otpError = false;
  cardNameCredit: any;
  deleteCard = false;
  saveCardCheckReq = true;
  invoiceID: string;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  enablecvvdata: number;
  showcvvlist: boolean[];
  mobileno: any;
  showretrypage = false;
  isexpirydatevalid = false;
  cvvStatus = false;
  ExpirydateError = '';
  expiryvalid = true;
  CardNumError = '';
  cardnumvalid = true;
  cardvalid = true;
  CardError = '';
  iscardValidate = true;
  cardlength: any;
  lightboxEnable: boolean;
  resent = false;
  regexps: any = {};
  translatedMsgs: any;
  savecardOption = true;
  errorAlert = false;
  errorAlertMessage = '';
  previousScreen: string;
  bankIds: Array<{ bankId: string, bankName: string, cardType: string, processorName?: string, cardScheme?: string, cardCountry?: string, binNumber?: string }> = [];
  selectedBank = '';
  selectedBankId: string;
  planList: Array<{
    emiPerMonth: string,
    interestRate: string,
    processingFees: string,
    processingFeeIndicator: 'P' | 'A',
    tenure: string,
    totalAmount: string,
    bankProcessorId: string
  }> = [];
  currency: string;
  fetchedBankId: string;
  fetchedBankName: string;
  fetchedBinNumber: string;
  fetchedCardCountry: string;
  fetchedCardScheme: string;
  fetchedCardType: string;
  emiPayload: { plan: EMIPlanBean; type: string; } = null;
  PageTypes = PageTypes;
  paymentInfo: IPaymentInfo;
  CardScreens = CardScreens;
  hierarchyData: IHierarchyData;
  sessionData: ISessionData;
  splitTxnInfo: ISplitTxnData = null;
  splitTxnStatus = false;
  txnData: ITxnData = {} as ITxnData;
  txnStatus: boolean = false;
  retryScreen = '';
  someInputField: any;
  otpTransStatus: any;
  isFinalMessage: boolean = false;
  resmessage: any;
  responseCode: string;
  paymentResponseData: any = [];
  transactionResponseDataVal: any = [];
  transactionResponseDataValfull: any;
  urlCheckConnection: any;
  payMode: any;
  cardRole: any;
  initiateContextPath: string;
  paymentInstrumentType: string;
  initiateContextPathExists = false;
  transactionId: string;
  isFallback: boolean;
  childWindow: Window;
  referenceOrderId: any;
  referenceStatus: boolean;
  cardType: string;
  processorName: any;
  showNoPlans: boolean;
  isTokenExpiryPresent: boolean;
  srRuleId: any;
  srMid: any;
  oldPaymentFlow: any;
  IFetchPlansPayload: IFetchPlansPayload;
  skipOrderIdCheckFlag: boolean;

  constructor(
    private cnpdataService: CnpdataService,
    public headerService: HeaderService,
    private userdataService: UserdataService,
    private lightboxdataService: LightboxdataService,
    private paymentRoles: PaymentRolesService,
    private translate: TranslateService,
    private emiPaymentsService: EmiPaymentsService,
    private _route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private paymentRole: PaymentRolesService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    this.headerService.getHeaderCurrency().subscribe(resp => this.currency = resp);
    this.headerService.getSplitTxnStatus().subscribe(resp => this.splitTxnStatus = resp);
    this.translate.get([
      'checkoutModule.cardnuminvalid',
      'checkoutModule.cardnotsupported',
      'checkoutModule.emiCardNA'
    ]).subscribe(res => this.translatedMsgs = res);
    const roles = this.paymentRoles.getRoles();
    for (const instrument of Object.keys(roles)) {
      if (roles[instrument].paymentInstrumentType.match(/card/i)) {
        this.cardRole = roles[instrument];
      }
      if (roles[instrument].paymentInstrumentType.match(/card/i) && roles[instrument].regex) {
        this.regexps[instrument] = {
          regex: roles[instrument].regex,
          logo: roles[instrument].logo
        };
      }
    }
    if (this.cardRole) {
      this.initiateContextPath = this.cardRole.initiatePaymentContextPath;
      this.paymentInstrumentType = this.cardRole.paymentInstrumentType;
      this.oldPaymentFlow = this.cardRole.oldPaymentFlow;
    }
    this.initiateContextPathExists = !this.oldPaymentFlow && this.initiateContextPath === ResponseContextPath.initiatePayment;
  }

  private isServerDown;
  private callBackOnCancelOTP: any;
  public windowReference: any;
  encRequest: any;
  actionUrl: any;
  newSimulatorUrl: any;
  currentRouter = this.router.url;

  reLoad() {
    this.router.navigate([this.currentRouter])
  }

  closeAlert(): void {
    this.errorAlert = false;
  }

  openAlert(message: string): void {
    this.errorAlert = true;
    this.errorAlertMessage = message;
  }

  dataInit(): void {
    this.getCardList();
    this.getPreAuthMsg();
  }

  initLbox(): void {
    this.lightBoxData = this.userdataService.getLightboxInfo();
    this.lboxAdditionalDetails = this.userdataService.getAdditionalData();
    if (this.lightBoxData.recurringFlag === 'Y' ||
      this.lightBoxData.transactionType === fieldnames.saveCard) {
      this.savecardOption = false;
    }
    this.dataInit();
  }

  initInv(): void {
    this.lightBoxData = {} as ILightBoxData;
    this.lightBoxData.transactionType = this.PageTypes.INV;
    this.paymentInfo = this.userdataService.getPaymentInfo();
    this.otpSent = false;
    this.currentScreen = this.CardScreens.OTP;
    this.hierarchyData = this.userdataService.getHierarchyData();
    this.sendOtp();
  }

  ngAfterViewInit() {
    const tokenExpiryElement = this.elementRef.nativeElement.querySelector('#tokenExpiry');
    this.isTokenExpiryPresent = tokenExpiryElement == null;
  }

  sendOtp() {
    this.cnpdataService.sendOtp(
      this.sessionData.sessionId, this.hierarchyData.paymentChannelId,
      this.hierarchyData.hierLabelIdReference, this.paymentInfo.mobilePreFix,
      this.paymentInfo.mobileNumber, this.paymentInfo.mailId,
      this.hierarchyData.hierarchyCriteria
    ).subscribe(resp => {
      this.otp1 = null;
      this.otp2 = null;
      this.otp3 = null;
      this.otp4 = null;
      setTimeout(() => this.otp1Field.nativeElement.focus(), 0);
      if (resp.responseCode === OtpResponseConstants.successOtpSent || resp.responseCode === OtpResponseConstants.otpSendSuccess) {
        this.otpSent = true;
        this.successMessage = resp.responseMessage;
        this.otpError = false;
        this.mobileno = this.paymentInfo.mobileNumber;
      } else if (resp.responseCode === OtpResponseConstants.otpResendLimitExceeded) {
        this.otpSent = true;
        this.successMessage = resp.responseMessage;
        this.otpError = false;
        this.exhausted = true;
        setTimeout(() => {
          this.gotoHome();
        }, 1000);
      } else {
        this.otpError = true;
        this.otpSent = false;
        this.exhausted = false;
        this.errorMessage = resp.responseMessage;
        setTimeout(() => {
          this.gotoHome();
        }, 1000);
      }
    }, error => this.handleError(error));
  }

  ngOnInit() {
    this.sessionData = this.userdataService.getSessionData();
    this.lightboxEnable = this.headerService.getPageType() === PageTypes.LB;
    if (this.lightboxEnable) {
      this.initLbox();
    } else {
      this.initInv();
    }
  }

  onBankSelect(bank: { bankId: string, bankName: string, cardType: string, processorName: string, cardScheme?: string, cardCountry?: string, binNumber?: string }): void {
    if (this.initiateContextPathExists) {
      this.onBankSelectForWeb(bank);
      return;
    }
    if (this.selectedBankId !== bank.bankId) {
      if (this.lightboxEnable) {
        this.emiPaymentsService.fetchPlans(
          this.lightBoxData.hierLabelIdReference,
          this.lightBoxData.transactionAmount,
          this.lightBoxData.paymentChannelId,
          bank.bankId
        ).subscribe(resp => {
          if (resp.responseCode === ResponseCode.emiPlans) {
            this.planList = resp.emiList;
          } else {
            this.handleError();
          }
        }, error => this.handleError(error));
      } else {
        this.emiPaymentsService.fetchPlans(
          this.hierarchyData.hierLabelIdReference,
          this.paymentInfo.amount.toString(),
          this.hierarchyData.paymentChannelId,
          bank.bankId
        ).subscribe(resp => {
          if (resp.responseCode === ResponseCode.emiPlans) {
            this.planList = resp.emiList;
          } else {
            this.handleError();
          }
        }, error => this.handleError(error));
      }
    }
    this.selectedBank = bank.bankName + ' ' + bank.cardType;
    this.selectedBankId = bank.bankId;
  }

  onBankSelectForWeb(bank: { bankId: string, bankName: string, cardType: string, processorName: string, cardScheme?: string, cardCountry?: string, binNumber?: string }): void {
    this.planList = [];
    this.showNoPlans = false;
    if (this.selectedBankId !== bank.bankId) {
      if (this.lightboxEnable) {
        const plansPayload: IFetchPlansPayload = {
          hierLabelIdReference: this.lightBoxData.hierLabelIdReference,
          transactionAmount: this.lightBoxData.transactionAmount,
          paymentChannelId: this.lightBoxData.paymentChannelId,
          bankId: bank.bankId,
        }
        if (bank.processorName) {
          plansPayload.processorName = bank.processorName;
        } else {
          plansPayload.processorCheck = true;
          plansPayload.currency = this.lightBoxData.currency;
          plansPayload.cardType = bank.cardType;
          plansPayload.cardScheme = bank.cardScheme;
          plansPayload.cardCountry = bank.cardCountry;
          plansPayload.binNumber = bank.binNumber;
          plansPayload.bankName = bank.bankName;
        }
        this.emiPaymentsService.fetchPlansForWeb(plansPayload).subscribe(resp => {
          if (resp.responseCode === ResponseCode.emiPlans && resp.emiList && resp.emiList.length > 0) {
            this.planList = resp.emiList;
          } else {
            this.showNoPlans = true;
          }
        }, error => this.showNoPlans = true);
      } else {
        const plansPayload: IFetchPlansPayload = {
          hierLabelIdReference: this.hierarchyData.hierLabelIdReference,
          transactionAmount: this.paymentInfo.amount.toString(),
          paymentChannelId: this.hierarchyData.paymentChannelId,
          bankId: bank.bankId,
        }
        if (bank.processorName) {
          plansPayload.processorName = bank.processorName;
        } else {
          plansPayload.processorCheck = true;
          plansPayload.currency = this.paymentInfo.currencyCode;
          plansPayload.cardType = bank.cardType;
          plansPayload.cardScheme = bank.cardScheme;
          plansPayload.cardCountry = bank.cardCountry;
          plansPayload.binNumber = bank.binNumber;
          plansPayload.bankName = bank.bankName;
        }
        this.emiPaymentsService.fetchPlansForWeb(plansPayload).subscribe(resp => {
          if (resp.responseCode === ResponseCode.emiPlans && resp.emiList && resp.emiList.length > 0) {
            this.planList = resp.emiList;
          } else {
            this.showNoPlans = true;
          }
        }, error => this.showNoPlans = true);
      }
    }
    this.selectedBank = bank.bankName + ' ' + bank.cardType;
    this.selectedBankId = bank.bankId;
  }

  getAllBankIds(): void {
    if (this.lightboxEnable) {
      this.emiPaymentsService.fetchAllBankIds(
        this.lightBoxData.hierLabelIdReference, this.lightBoxData.transactionAmount,
        this.lightBoxData.paymentChannelId
      ).subscribe(resp => {
        if (resp.responseCode === ResponseCode.successResponseCode) {
          this.bankIds = resp.bankList.map(bank => {
            return {
              ...bank,
              logo: this.getLogoNameFromUrl(bank.logo)
            };
          });
          this.goTo(this.CardScreens.ALLPLANS, true);
        }
      });
    } else {
      this.emiPaymentsService.fetchAllBankIds(
        this.hierarchyData.hierLabelIdReference, this.paymentInfo.amount.toString(),
        this.hierarchyData.paymentChannelId
      ).subscribe(resp => {
        if (resp.responseCode === ResponseCode.successResponseCode) {
          this.bankIds = resp.bankList;
          this.goTo(this.CardScreens.ALLPLANS, true);
        }
      });
    }
  }

  getLogoNameFromUrl(url: string): string {
    const parts = url.split('/');
    const fileName = parts[parts.length - 1];
    const nameWithoutExtension = fileName.split('.')[0];
    const withoutPrefix = nameWithoutExtension.replace('paymentInstrument_', ''); // Remove the prefix
    return withoutPrefix;
  }

  goBack(): void {
    this.clearFields();
    if ((this.cardTokenList && this.cardTokenList.length) || (this.initiateContextPathExists && this.cards && this.cards.length)) {
      this.currentScreen = this.CardScreens.SAVEDCARDS;
    } else {
      this.gotoHome();
    }
  }

  clearFields(): void {
    this.cardForm.reset();
    this.cardLogo = '';
    this.cardImage = '';
    this.CardNumError = '';
    this.expDateValid = true;
    this.cvvValid = true;
  }

  gotoHome(clear?: boolean): void {
    this.showHome.emit(clear);
  }

  private setPaymentMode;
  goToPlans(getPaymentMode) {
    this.getAllBankIds();

  }

  clearCVV(): void {
    this.cvvFieldValue = '';
    this.savedCardCVV = '';
  }

  goTo(screen: string, clearFactor?: boolean): void {
    if (clearFactor) {
      this.clearCVV();
    }
    if (screen === CardScreens.ALLPLANS) {
      this.planList = [];
    }
    this.previousScreen = this.currentScreen;
    this.currentScreen = screen;
  }

  getPlans(): void {
    if (this.initiateContextPathExists) {
      this.getPlansForWeb();
      return;
    }
    let bankId = '';
    this.planList = [];
    if (this.currentScreen === this.CardScreens.SAVEDCARDS) {
      bankId = this.cardTokenList[Number(this.selectedCard)].bankId;
    } else {
      bankId = this.fetchedBankId;
    }
    if (this.lightboxEnable) {
      this.emiPaymentsService.fetchPlans(
        this.lightBoxData.hierLabelIdReference,
        this.lightBoxData.transactionAmount,
        this.lightBoxData.paymentChannelId,
        bankId
      ).subscribe(resp => {
        if (resp.responseCode === ResponseCode.emiPlans) {
          this.planList = resp.emiList;
          this.goTo(this.CardScreens.SELECTPLANS, false);
        } else {
          this.handleError();
        }
      }, error => this.handleError(error));
    } else {
      this.emiPaymentsService.fetchPlans(
        this.hierarchyData.hierLabelIdReference,
        this.paymentInfo.amount.toString(),
        this.hierarchyData.paymentChannelId,
        bankId
      ).subscribe(resp => {
        if (resp.responseCode === ResponseCode.emiPlans) {
          this.planList = resp.emiList;
          this.goTo(this.CardScreens.SELECTPLANS, false);
        } else {
          this.handleError();
        }
      }, error => this.handleError(error));
    }

  }

  getPlansForWeb(): void {
    let bankId = '';
    let processor = '';
    this.planList = [];
    this.showNoPlans = false;
    let bankName = '';
    let binNumber = '';
    let cardCountry = '';
    let cardScheme = '';
    let cardType = '';
    if (this.currentScreen === this.CardScreens.SAVEDCARDS) {
      bankId = this.cardTokenList[Number(this.selectedCard)].bankId;
      processor = this.cardTokenList[Number(this.selectedCard)].processorName;
      bankName = this.cardTokenList[(this.selectedCard)].bankName;
      binNumber = this.cardTokenList[(this.selectedCard)].binNumber;
      cardCountry = this.cardTokenList[(this.selectedCard)].cardCountry;
      cardScheme = this.cardTokenList[(this.selectedCard)].cardScheme;
      cardType = this.cardTokenList[(this.selectedCard)].cardType;
    } else {
      bankId = this.fetchedBankId;
      processor = this.processorName;
      bankName = this.fetchedBankName;
      binNumber = this.fetchedBinNumber;
      cardCountry = this.fetchedCardCountry;
      cardScheme = this.fetchedCardScheme;
      cardType = this.fetchedCardType;
    }
    this.processorName = processor;
    if (this.lightboxEnable) {
      const plansPayload: IFetchPlansPayload = {
        hierLabelIdReference: this.lightBoxData.hierLabelIdReference,
        transactionAmount: this.lightBoxData.transactionAmount,
        paymentChannelId: this.lightBoxData.paymentChannelId,
        bankId, bankName, binNumber, cardCountry, cardScheme, cardType
      }
      if (processor) {
        plansPayload.processorName = processor;
      } else {
        plansPayload.processorCheck = true;
        plansPayload.currency = this.lightBoxData.currency;
        plansPayload.cardType = this.IFetchPlansPayload.cardType;
        plansPayload.cardScheme = this.IFetchPlansPayload.cardScheme;
        plansPayload.cardCountry = this.IFetchPlansPayload.cardCountry;
        plansPayload.binNumber = this.IFetchPlansPayload.binNumber;
      }
      this.emiPaymentsService.fetchPlansForWeb(plansPayload).subscribe(resp => {
        if (resp.responseCode === ResponseCode.emiPlans && resp.emiList && resp.emiList.length > 0) {
          this.planList = resp.emiList;
          this.goTo(this.CardScreens.SELECTPLANS, false);
        } else {
          this.showNoPlans = true;
        }
      }, error => this.showNoPlans = true);
    } else {
      const plansPayload: IFetchPlansPayload = {
        hierLabelIdReference: this.hierarchyData.hierLabelIdReference,
        transactionAmount: this.paymentInfo.amount.toString(),
        paymentChannelId: this.hierarchyData.paymentChannelId,
        bankId,
      }
      if (processor) {
        plansPayload.processorName = processor;
      } else {
        plansPayload.processorCheck = true;
        plansPayload.currency = this.paymentInfo.currencyCode;
        plansPayload.cardType = this.IFetchPlansPayload.cardType;
        plansPayload.cardScheme = this.IFetchPlansPayload.cardScheme;
        plansPayload.cardCountry = this.IFetchPlansPayload.cardCountry;
        plansPayload.binNumber = this.IFetchPlansPayload.binNumber;
      }
      this.emiPaymentsService.fetchPlansForWeb(plansPayload).subscribe(resp => {
        if (resp.responseCode === ResponseCode.emiPlans && resp.emiList && resp.emiList.length > 0) {
          this.planList = resp.emiList;
          this.goTo(this.CardScreens.SELECTPLANS, false);
        } else {
          this.showNoPlans = true;
        }
      }, error => this.showNoPlans = true);
    }
  }

  goToSelectPlans(getPaymentMode) {
    this.getPlans();
    this.setPaymentMode = getPaymentMode;
  }

  goToPreviousScreen() {
    this.currentScreen = this.previousScreen;
    this.selectedBankId = '';
    this.selectedBank = '';
    this.clearCVV();
  }

  emiPayEvent(payload: { plan: EMIPlanBean, type: string }): void {
    this.emiPayload = payload;
    if (payload.type === 'savedCards') {
      this.proceedToPay('saved');
    } else {
      this.proceedToPay('new');
    }
  }

  retry(changePayload: boolean): void {
    this.fallback = 'N';
    if (this.splitTxnInfo != undefined || this.splitTxnInfo != null) {
      if (this.splitTxnInfo.remainingAmount != undefined || this.splitTxnInfo.remainingAmount != null) {
        this.loyaltyFlag = this.fieldNames.YES;
      }
    }

    if (this.lightboxEnable) {
      this.lightBoxData = this.userdataService.getLightboxInfo();
    } else {
      this.paymentInfo = this.userdataService.getPaymentInfo();
    }
    if (this.setPaymentMode != 'OnlyForEMIs') {
      this.clearCVV();
    }
    if (changePayload) {
      this.gotoHome(true);
    }
    if (this.cardTypeFromGlobalScope === 'saved') {
      this.currentScreen = this.CardScreens.SAVEDCARDS;
      this.clearCVV();
    } else if (this.cardTypeFromGlobalScope === 'new') {
      this.currentScreen = this.CardScreens.HOME;
      setTimeout(() => this.cardAField.nativeElement.focus(), 0);
    } else {
      this.currentScreen = this.CardScreens.HOME;
      setTimeout(() => this.cardAField.nativeElement.focus(), 0);
    }

    this.headerService.changeHeaderStatus(true);
    this.skipOrderIdCheckFlag = true;
  }

  showTxnComplete(): void {
    this.headerService.changeHeaderStatus(false);
    this.currentScreen = this.CardScreens.COMPLETE;
    this.loadingFalse();
  }

  stringToHTML(str) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, 'text/html');
    return (<HTMLInputElement>doc.getElementById("encRequest")).value;
  }

  stringToAction(str) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, 'text/html');
    return (<HTMLFormElement>doc.getElementById("nonseamless")).action;
  }
  private cardTypeFromGlobalScope: any;
  private ifServerDown: any;

  proceedToPay(cardType: string) {
    this.cardTypeFromGlobalScope = cardType;
    if (this.splitTxnStatus) {
      this.splitTxnInfo = this.userdataService.getSplitTxnInfo();
    }
    this.retryScreen = this.currentScreen;

    if (this.initiateContextPathExists) {
      this.cardType = cardType;
      this.initiatePayment('N');
      return;
    }

    if (this.lightboxEnable) {
      this.additionalData = JSON.stringify(this.lboxAdditionalDetails);
      if (cardType === 'saved') {
        this.lightboxdataService.proceedToPayToken(this.savedCardCVV, this.cardTokenList[this.selectedCard].token, this.additionalData,
          this.sessionData.sessionId, this.lightBoxData, this.emiPayload, this.splitTxnInfo, this.loyaltyFlag, this.fallback).subscribe(resp => {
            this.ifServerDown = resp;
            if (resp.ecommPaymentResponseList) {
              this.paymentResponseData = resp.ecommPaymentResponseList;
              resp.ecommPaymentResponseList.forEach((element, ind) => {
                if (ind == 0) {
                  this.txnData.responseCode = element.responseCode;
                  this.txnData.responseMessage = element.responseMessage;
                  this.txnData.referenceOrderId = element.referenceOrderId;
                  this.txnData.recurrentToken = element.recurrentToken;
                  if (element.responseCode === ResponseCode.successResponseCode || element.responseCode === ResponseCode.loyaltySaleSuccess) {
                    if (element.htmlRequest != undefined) {
                      this.encRequest = this.stringToHTML(resp.ecommPaymentResponseList[0].htmlRequest);
                      this.actionUrl = this.stringToAction(resp.ecommPaymentResponseList[0].htmlRequest);
                      this.headerService.changeLoaderStatus(true);
                      this.simulatorPageCall(this.encRequest);
                    }
                    if (element.htmlRequest == undefined && resp.ecommPaymentResponseList[0].responseCode === ResponseCode.successResponseCode) {
                      this.txnStatus = true;
                    }
                  }
                }
              });
              if (this.txnStatus && this.splitTxnInfo && resp.ecommPaymentResponseList.length < 1) {
                this.txnStatus = false;
              }
            }
            this.showTxnComplete();

          }, err => {
            this.txnStatus = false;
            this.showTxnComplete();
          });
      } else {
        const yearFieldData = this.yearFieldValue.toString();
        this.cardExpiry = `${this.monthFieldValue.toString()}-${yearFieldData}`;
        const cvvValue = this.cvvFieldValue;

        const re = /[\s-\s]/gi;
        const cardValue = this.cardNumber.replace(re, '');
        if (this.addCardCheck) {
          this.saveCard = 'on';
        }
        this.lightboxdataService.proceedToPay(cardValue, this.cardExpiry, this.nameOnCard,
          cvvValue, this.saveCard,
          this.additionalData, this.sessionData.sessionId, this.lightBoxData,
          'SDK', this.emiPayload, this.splitTxnInfo, this.loyaltyFlag, this.fallback).subscribe(resp => {
            this.callBackOnCancelOTP = resp.ecommPaymentResponseList[0].cardToken;
            if (resp.ecommPaymentResponseList) {
              this.paymentResponseData = resp.ecommPaymentResponseList;
              resp.ecommPaymentResponseList.forEach((element, ind) => {
                if (ind == 0) {
                  this.txnData.responseCode = element.responseCode;
                  this.txnData.responseMessage = element.responseMessage;
                  this.txnData.referenceOrderId = element.referenceOrderId;
                  this.txnData.recurrentToken = element.recurrentToken;
                  if (element.responseCode === ResponseCode.successResponseCode ||
                    element.responseCode === ResponseCode.loyaltySaleSuccess) {
                    if (element.htmlRequest != undefined) {
                      this.encRequest = this.stringToHTML(resp.ecommPaymentResponseList[0].htmlRequest);
                      this.actionUrl = this.stringToAction(resp.ecommPaymentResponseList[0].htmlRequest);
                      this.headerService.changeLoaderStatus(true);
                      this.simulatorPageCall(this.encRequest);
                    }
                    if (element.htmlRequest == undefined && resp.ecommPaymentResponseList[0].responseCode === ResponseCode.successResponseCode) {
                      this.txnStatus = true;
                    }
                  }
                  this.showTxnComplete();
                }
              });
              if (this.txnStatus && this.splitTxnInfo && resp.ecommPaymentResponseList.length < 1) {
                this.txnStatus = false;
              }
            }
            if (resp.ecommPaymentResponseList[0].responseCode === "7718") {
              this.txnStatus = false;
              this.paymentRoles.getRetryOrderId(
                this.lightBoxData.callbackurl, this.lightBoxData.orderId, this.lightBoxData.transactionType, this.lightBoxData.transactionAmount
              ).subscribe(response => {
                if (response.responseCode === ResponseCode.successResponseCode) {
                  this.lightBoxData.orderId = response.orderId;
                  this.lightBoxData.checkSumPayment = response.checksumpayment;
                  this.userdataService.setLightboxInfo(this.lightBoxData);
                  this.openAlert(resp.ecommPaymentResponseList[0].responseMessage);
                }
              });
            }
            this.showTxnComplete();
          }, err => {
            this.txnStatus = false;
            this.showTxnComplete();
          });
      }
    } else {
      setTimeout(() => {
        this.headerService.changeLoaderStatus(false);
      }, 3000);
      setTimeout(() => {
        if (cardType === 'saved') {
          this.additionalData = JSON.stringify({ electronicGoodsIndicator: 'D', invoiceId: this.paymentInfo.id });
          this.cnpdataService.proceedToPayToken(this.savedCardCVV, this.cardTokenList[this.selectedCard].token,
            this.additionalData, this.sessionData.sessionId, this.paymentInfo, this.hierarchyData, this.emiPayload, this.fallback).subscribe(resp => {
              if (resp.ecommPaymentResponseList) {
                this.paymentResponseData = resp.ecommPaymentResponseList;
                resp.ecommPaymentResponseList.forEach((element, ind) => {
                  if (ind == 0) {
                    this.txnData.responseCode = element.responseCode;
                    this.txnData.responseMessage = element.responseMessage;
                    this.txnData.referenceOrderId = element.referenceOrderId;
                    this.txnData.recurrentToken = element.recurrentToken;
                    if (element.responseCode === ResponseCode.successResponseCode) {
                      if (element.htmlRequest != undefined) {
                        this.encRequest = this.stringToHTML(resp.ecommPaymentResponseList[0].htmlRequest);
                        this.actionUrl = this.stringToAction(resp.ecommPaymentResponseList[0].htmlRequest);
                        this.headerService.changeLoaderStatus(true);
                        this.simulatorPageCall(this.encRequest);
                      }
                      if (element.htmlRequest == undefined && resp.ecommPaymentResponseList[0].responseCode === ResponseCode.successResponseCode) {
                        this.cnpdataService.setpaidStatus(this.paymentInfo.id).subscribe(resp => {
                          this.txnStatus = true;
                          setTimeout(() => {
                            this.loadingFalse();
                          }, -5000);
                          this.showTxnComplete();
                        });
                      }
                    }
                  }
                });
              }
            }, err => {
              this.txnStatus = false;
              this.showTxnComplete();
            });
        } else {
          const re = /[\s-\s]/gi;
          const cardValue = this.cardNumber.replace(re, '');
          if (this.addCardCheck) {
            this.saveCard = 'on';
          }
          const yearFieldData = this.yearFieldValue.toString();
          this.cardExpiry = `${this.monthFieldValue.toString()}-${yearFieldData}`;
          const cvvValue = this.cvvFieldValue;
          this.additionalData = JSON.stringify({ electronicGoodsIndicator: 'D', invoiceId: this.paymentInfo.id });
          this.cnpdataService.proceedToPay(cardValue, this.cardExpiry, this.nameOnCard, cvvValue, this.saveCard,
            this.additionalData, this.sessionData.sessionId, this.paymentInfo, this.hierarchyData, this.emiPayload, this.fallback).subscribe(resp => {
              this.callBackOnCancelOTP = resp.ecommPaymentResponseList[0].cardToken;
              if (resp.ecommPaymentResponseList) {
                this.paymentResponseData = resp.ecommPaymentResponseList;
                resp.ecommPaymentResponseList.forEach((element, ind) => {
                  if (ind == 0) {
                    this.txnData.responseCode = element.responseCode;
                    this.txnData.responseMessage = element.responseMessage;
                    this.txnData.referenceOrderId = element.referenceOrderId;
                    this.txnData.recurrentToken = element.recurrentToken;
                    if (element.responseCode === ResponseCode.successResponseCode) {
                      if (element.htmlRequest != undefined) {
                        this.encRequest = this.stringToHTML(resp.ecommPaymentResponseList[0].htmlRequest);
                        this.actionUrl = this.stringToAction(resp.ecommPaymentResponseList[0].htmlRequest);
                        this.headerService.changeLoaderStatus(true);
                        this.simulatorPageCall(this.encRequest);
                      }
                      if (element.htmlRequest == undefined && resp.ecommPaymentResponseList[0].responseCode === ResponseCode.successResponseCode) {
                        this.cnpdataService.setpaidStatus(this.paymentInfo.id).subscribe(resp1 => {
                          this.txnStatus = true;
                          setTimeout(() => {
                            this.loadingFalse();
                          }, -5000);
                          this.showTxnComplete();
                        });
                      }
                    }
                  }
                });
              }
              this.txnStatus = false;
              this.showTxnComplete();
            }, err => {
              this.txnStatus = false;
              this.showTxnComplete();
            });
        }
      }, 8000);
    }
  }

  initiatePayment(fallback) {
    this.transactionId = '';
    if (this.splitTxnStatus) {
      this.splitTxnInfo = this.userdataService.getSplitTxnInfo();
    }

    this.isFallback = fallback === "Y";

    let height = 500;
    let width = screen.width * 0.6;
    var left = (screen.width - width) / 2;
    var top = (screen.height - height) / 2;

    // if (!this.isFallback) {
    //   this.childWindow = window.open("", '_blank', `toolbar=0, scrollbar=yes, width=${width}, height=${height}, left=${left}, top=${top}`);
    //   this.childWindow.document.write("<style> body { justify-content: center; align-items: center; flex-direction: column; display: flex; padding: 40px } .loader { margin-top: 20px; border: 5px solid #f3f3f3; border-radius: 50%; border-top: 5px solid #3498db; width: 30px; height: 30px; -webkit-animation: spin 2s linear infinite; /* Safari */ animation: spin 2s linear infinite; } /* Safari */ @-webkit-keyframes spin { 0% { -webkit-transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); } } @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } } </style> </head> <body> <div class='loader'></div> </body> </html>");
    // }

    const domainName = this.extractDomainFromUrl(environment.api_url);
    const primaryWebhookURL = `https://${domainName}/service-integrator/txnstatus/webhook`;
    const fallbackWebhookURL = `https://${domainName}/service-integrator/txnstatus/fallback/webhook`;

    const { customerFirstName = "", customerLastName = "" } = this.lboxAdditionalDetails || {};
    if (this.lightboxEnable) {
      let cardPayload: ICardPayload = {
        paymentChannelId: this.lightBoxData.paymentChannelId,
        hierLabelIdReference: this.lightBoxData.hierLabelIdReference,
        hierarchyCriteria: this.lightBoxData.hierarchyCriteria,
        consumerMsisdn: this.lightBoxData.mobile,
        amount: this.lightBoxData.transactionAmount,
        taxAmount: '1',
        taxIncluded: '2',
        currencyCode: this.lightBoxData.currency,
        timezone: "India Standard Time",
        emailAddress: this.lightBoxData.email,
        saveToken: this.addCardCheck ? 'Yes' : 'No',
        orderId: this.lightBoxData.orderId,
        customerCode: this.lightBoxData.mobile,
        checkSumPayment: this.lightBoxData.checkSumPayment,
        electronicGoodsIndicator: 'D',
        additionalDetails: JSON.stringify(this.lboxAdditionalDetails),
        customerFirstName,
        customerLastName,
        fallback,
        method: this.isEmiScreen ? 'CardEmi' : 'Card',
        primaryWebhookURL,
        fallbackWebhookURL,
        ...(this.skipOrderIdCheckFlag ? { skipOrderIdCheck: true } : {}),
        merchantRedirectURL: "https://uat-r2.fnp.com/checkout/order-confirmation?orderId=48959500"
      };

      if (this.cardTypeFromGlobalScope === 'saved') {
        cardPayload.cardToken = this.cardTokenList[this.selectedCard].cardToken
        cardPayload.tokenId = this.cardTokenList[this.selectedCard].tokenId
        cardPayload.cvv = this.savedCardCVV;
        cardPayload.isToken = 'Yes';
        cardPayload.applicationLabel = this.cardTokenList[this.selectedCard].cardScheme;
        cardPayload.cardType = this.cardTokenList[this.selectedCard].cardType;
        cardPayload.issueBank = this.cardTokenList[this.selectedCard].issueBank;
      } else {
        const re = /[\s-\s]/gi;
        const cardValue = this.cardNumber.replace(re, '');
        const yearFieldData = this.yearFieldValue.toString();
        this.cardExpiry = `${this.monthFieldValue.toString()}/${yearFieldData}`;
        cardPayload.cardNumber = cardValue;
        cardPayload.expiry = this.cardExpiry;
        cardPayload.cvv = this.cvvFieldValue;
        cardPayload.isToken = 'No';
        cardPayload.cardName = this.nameOnCard;
      }

      if (this.isEmiScreen) {
        cardPayload.skipProcessorCheck = true
        cardPayload.processorName = this.emiPayload.plan.processorName
        cardPayload.emiDuration = this.emiPayload.plan.tenure
        cardPayload.emiBankId = this.emiPayload.plan.bankProcessorId
        cardPayload.srRuleId = this.cardTypeFromGlobalScope === 'saved' ? this.cardTokenList[this.selectedCard].srRuleId : this.srRuleId
        cardPayload.srMid = this.cardTypeFromGlobalScope === 'saved' ? this.cardTokenList[this.selectedCard].srMid : this.srMid
      }

      this.cnpdataService.initiateCardPayment(
        this.initiateContextPath, cardPayload,
        this.splitTxnInfo
      ).subscribe(resp => {
        const { initiatePaymentResponses = [] } = resp || {};
        if (initiatePaymentResponses.length > 0) {
          const response = initiatePaymentResponses[0];
          if (response.redirectURL) {
            if (environment.redirectHtmlFlag == 'TRUE') {
              window.open(response.redirectURL);
              return;
            }
            else {
              if (!this.isFallback) {
                this.childWindow = window.open("", '_blank', `toolbar=0, scrollbar=yes, width=${width}, height=${height}, left=${left}, top=${top}`);
                this.childWindow.document.write("<style> body { justify-content: center; align-items: center; flex-direction: column; display: flex; padding: 40px } .loader { margin-top: 20px; border: 5px solid #f3f3f3; border-radius: 50%; border-top: 5px solid #3498db; width: 30px; height: 30px; -webkit-animation: spin 2s linear infinite; /* Safari */ animation: spin 2s linear infinite; } /* Safari */ @-webkit-keyframes spin { 0% { -webkit-transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); } } @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } } </style> </head> <body> <div class='loader'></div> </body> </html>");
              }
            }
          }
        }
        if (initiatePaymentResponses.length <= 0) {
          this.childWindow && this.childWindow.close();
          this.handleError();
          return;
        }
        const htmlResponse = initiatePaymentResponses.filter(e => e.hasOwnProperty("modId")) || [];
        if (htmlResponse.length <= 0) {
          this.childWindow && this.childWindow.close();
          this.handleError();
          return;
        }
        const response = htmlResponse[0];
        if (response.referenceOrderId) {
          this.referenceOrderId = response.referenceOrderId;
        }
        if (response.transactionId) {
          this.transactionId = response.transactionId;
        }
        if (response.responseCode === ResponseCode.successResponseCode) {
          this.txnStatus = true;
        } else {
          this.txnStatus = false;
        }

        if (!this.transactionId || this.txnStatus == false) {
          this.childWindow && this.childWindow.close();
          this.handleError();
        } else {
          this.referenceStatus = true;
          if (this.splitTxnStatus) {
            this.txnData.referenceOrderId = this.referenceOrderId;
          } else {
            this.txnData.transactionId = this.transactionId;
          }
          this.loadCardResponseHTML(response.callBackUrl);
        }
      }, error => {
        this.childWindow && this.childWindow.close();
        this.handleError(error)
      });
    } else {
      this.additionalData = JSON.stringify({ electronicGoodsIndicator: 'D', invoiceId: this.paymentInfo.id });
      const checkSumPayment = this.generateCheckSum(this.paymentInfo.orderId, this.paymentInfo.amount.toString());

      let cardPayload: ICardPayload = {
        paymentChannelId: this.hierarchyData.paymentChannelId,
        hierLabelIdReference: this.hierarchyData.hierLabelIdReference,
        hierarchyCriteria: this.hierarchyData.hierarchyCriteria,
        consumerMsisdn: this.paymentInfo.mobileNumber,
        amount: this.paymentInfo.amount.toString(),
        taxAmount: '1',
        taxIncluded: '2',
        currencyCode: this.paymentInfo.currencyCode,
        timezone: "India Standard Time",
        emailAddress: this.paymentInfo.mailId,
        saveToken: this.addCardCheck ? 'Yes' : 'No',
        orderId: this.paymentInfo.orderId,
        customerCode: this.paymentInfo.mobileNumber,
        checkSumPayment,
        electronicGoodsIndicator: 'D',
        additionalDetails: this.additionalData,
        customerFirstName: this.paymentInfo.firstName,
        customerLastName: this.paymentInfo.lastName,
        fallback,
        method: this.isEmiScreen ? 'CardEmi' : 'Card',
        primaryWebhookURL,
        fallbackWebhookURL,
        ...(this.skipOrderIdCheckFlag ? { skipOrderIdCheck: true } : {}),
        merchantRedirectURL: "https://uat-r2.fnp.com/checkout/order-confirmation?orderId=48959500"
      };

      if (this.cardTypeFromGlobalScope === 'saved') {
        cardPayload.cardToken = this.cardTokenList[this.selectedCard].cardToken
        cardPayload.tokenId = this.cardTokenList[this.selectedCard].tokenId
        cardPayload.cvv = this.savedCardCVV;
        cardPayload.isToken = 'Yes';
        cardPayload.applicationLabel = this.cardTokenList[this.selectedCard].cardScheme;
        cardPayload.cardType = this.cardTokenList[this.selectedCard].cardType;
        cardPayload.issueBank = this.cardTokenList[this.selectedCard].issueBank;
      } else {
        const re = /[\s-\s]/gi;
        const cardValue = this.cardNumber.replace(re, '');
        const yearFieldData = this.yearFieldValue.toString();
        this.cardExpiry = `${this.monthFieldValue.toString()}/${yearFieldData}`;
        cardPayload.cardNumber = cardValue;
        cardPayload.expiry = this.cardExpiry;
        cardPayload.cvv = this.cvvFieldValue;
        cardPayload.isToken = 'No';
        cardPayload.cardName = this.nameOnCard;
      }

      if (this.isEmiScreen) {
        cardPayload.skipProcessorCheck = true
        cardPayload.processorName = this.emiPayload.plan.processorName
        cardPayload.emiDuration = this.emiPayload.plan.tenure
        cardPayload.emiBankId = this.emiPayload.plan.bankProcessorId
        cardPayload.srRuleId = this.cardTypeFromGlobalScope === 'saved' ? this.cardTokenList[this.selectedCard].srRuleId : this.srRuleId
        cardPayload.srMid = this.cardTypeFromGlobalScope === 'saved' ? this.cardTokenList[this.selectedCard].srMid : this.srMid
      }

      this.cnpdataService.initiateCardPayment(
        this.initiateContextPath, cardPayload, null
      ).subscribe(resp => {
        const { initiatePaymentResponses = [] } = resp || {};
        if (initiatePaymentResponses.length > 0) {
          const response = initiatePaymentResponses[0];
          if (response.redirectURL) {
            if (environment.redirectHtmlFlag == 'TRUE') {
              window.open(response.redirectURL);
              return;
            }
            else {
              if (!this.isFallback) {
                this.childWindow = window.open("", '_blank', `toolbar=0, scrollbar=yes, width=${width}, height=${height}, left=${left}, top=${top}`);
                this.childWindow.document.write("<style> body { justify-content: center; align-items: center; flex-direction: column; display: flex; padding: 40px } .loader { margin-top: 20px; border: 5px solid #f3f3f3; border-radius: 50%; border-top: 5px solid #3498db; width: 30px; height: 30px; -webkit-animation: spin 2s linear infinite; /* Safari */ animation: spin 2s linear infinite; } /* Safari */ @-webkit-keyframes spin { 0% { -webkit-transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); } } @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } } </style> </head> <body> <div class='loader'></div> </body> </html>");
              }
            }
          }
        }
        if (initiatePaymentResponses.length <= 0) {
          this.childWindow && this.childWindow.close();
          this.handleError();
          return;
        }
        const htmlResponse = initiatePaymentResponses.filter(e => e.hasOwnProperty("modId")) || [];
        if (htmlResponse.length <= 0) {
          this.childWindow && this.childWindow.close();
          this.handleError();
          return;
        }
        const response = htmlResponse[0];
        this.referenceOrderId = response.referenceOrderId;
        if (response.transactionId && response.responseCode === ResponseCode.successResponseCode) {
          this.referenceStatus = true;
          this.transactionId = response.transactionId;
          this.txnData.transactionId = this.transactionId;
          if (this.splitTxnStatus) {
            this.txnData.referenceOrderId = this.referenceOrderId;
          } else {
            this.txnData.transactionId = this.transactionId;
          }
          this.loadCardResponseHTML(response.callBackUrl);
        } else {
          this.childWindow && this.childWindow.close();
          this.handleError();
        }
      }, error => {
        this.childWindow && this.childWindow.close();
        this.handleError(error)
      });
    }
  }

  private extractDomainFromUrl(url: string): string {
    const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match !== null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      return match[2];
    } else {
      return '';
    }
  }

  async loadCardResponseHTML(htmlResponse) {
    if (this.childWindow) {
      try {
          this.renderHtmlContent(htmlResponse);
      } catch (error) {
        this.retryPaymentWithfallback();
      }
    }
  }

  renderHtmlContent(htmlResponse) {
    if (htmlResponse) {
      this.childWindow.document.write(htmlResponse);
      let timer = setInterval(() => {
        this.loadingTrue();
        if (this.childWindow.closed) {
          this.txnStatus = false;
          this.handlePolling();
          clearInterval(timer);
          this.childWindow = null;
        }
      }, 50);
    } else {
      this.childWindow && this.childWindow.close();
      this.handleError();
    }
  }

  retryPaymentWithfallback() {
    if (!this.isFallback) {
      this.updateUPITransactionStatus();
      this.initiatePayment('Y');
    } else {
      this.childWindow && this.childWindow.close();
      this.handleError();
    }
  }

  updateUPITransactionStatus() {
    this.lightboxdataService.updateTransStatus(this.transactionId).subscribe(resp => {
    })
  }

  handlePolling() {
    const checkSumPaymentforTxnStatus = this.generateCheckSumforTxnStatus(this.transactionId);
    this.lightboxdataService.otpTransStatusForWeb(this.transactionId, checkSumPaymentforTxnStatus).subscribe(resp => {
      if (resp) {
        const { ecommPaymentResponseList = [] } = resp;
        const ecommPaymentResponse = ecommPaymentResponseList[0] || {};
        if (ecommPaymentResponse.responseCode === ResponseCode.successResponseCode) {
          this.txnStatus = true;
        } else {
          this.txnStatus = false;
        }
      }
      if (!resp) {
        this.txnStatus = false;
      }
      if (this.txnStatus && !this.lightboxEnable) {
        this.cnpdataService.setpaidStatus(this.paymentInfo.id).subscribe(resp1 => {
          this.showTxnComplete();
        });
      } else {
        this.showTxnComplete();
      }
    },
      err => {
        if (err.status === 0 || err.status === 500 || err.status === 400) {
          this.txnStatus = false;
          this.showTxnComplete();
        }
      });
  }

  generateCheckSum(orderId, amount) {
    let checksumInputString = "";
    checksumInputString = checksumInputString.concat(amount).concat(orderId).concat("1").concat("D").concat("abcdefghhgfedcba");
    return shajs('sha256').update(checksumInputString).digest('hex');
  }

  generateCheckSumforTxnStatus(txnId) {
    let checksumInputStringforTxnStatus = "";
    checksumInputStringforTxnStatus = checksumInputStringforTxnStatus.concat(txnId).concat("abcdefghhgfedcba");
    return shajs('sha256').update(checksumInputStringforTxnStatus).digest('hex');
  }

  loadingFalse() {
    this.headerService.changeLoaderStatus(false);
  }
  loadingTrue() {
    this.headerService.changeLoaderStatus(true);
  }

  private getServerDownVal() {
    this.lightboxdataService.messageSubject.subscribe(message => {
      this.isServerDown = message;
    });
  }

  simulatorPageCall(encRequest: any) {
    this.getServerDownVal();
    let newSimulatorUrl = this.actionUrl + '?encRequest=' + encRequest;
    fetch(this.actionUrl, { mode: 'no-cors' })
      .then(response => {
        if ((response.status == 200 || response.status == 0) && navigator.onLine) {
          this.fallback = "N";
          let child = window.open(newSimulatorUrl, '_blank', 'toolbar=0, scrollbar=yes, width=450, height=500');
          let timer = setInterval(() => {
            this.loadingTrue();
            if (child.closed) {
              this.txnStatus = false;
              this.callBackResponseBank();
              this.loadingTrue();
              clearInterval(timer);
            }
          });
        }
      })

      .catch(err => {
        this.loyaltyFlag = this.fieldNames.NO;
        this.updateTransactionStatus();
        setTimeout(() => {
          this.loadingFalse();
        }, 8000);


        setTimeout(() => {
          if (this.lightboxEnable) {
            this.additionalData = JSON.stringify(this.lboxAdditionalDetails);
            const yearFieldData = this.yearFieldValue.toString();
            this.cardExpiry = `${this.monthFieldValue.toString()}-${yearFieldData}`;
            const cvvValue = this.cvvFieldValue;
            const re = /[\s-\s]/gi;
            const cardValue = this.cardNumber.replace(re, '');
            if (this.addCardCheck) {
              this.saveCard = 'on';
            }
            if (this.isServerDown != 'Yes') {
              if (this.fallback === 'Y') {
                this.txnStatus = false;
                return;
              }
              this.fallback = 'Y';
              if (this.fallback === 'Y') {
                let finalTransactionAmount = this.lightBoxData.transactionAmount;

                if (this.splitTxnInfo != undefined || this.splitTxnInfo != null) {
                  let remainAmount = this.splitTxnInfo.remainingAmount;
                  if (this.splitTxnInfo.remainingAmount != undefined || this.splitTxnInfo.remainingAmount != null) {
                    this.lightBoxData.transactionAmount = remainAmount.toString();
                  }
                }
                if (this.cardTypeFromGlobalScope === 'saved') {
                  this.lightboxdataService.proceedToPayToken(this.savedCardCVV, this.cardTokenList[this.selectedCard].token, this.additionalData,
                    this.sessionData.sessionId, this.lightBoxData, this.emiPayload, this.splitTxnInfo, this.loyaltyFlag, this.fallback).subscribe(resp => {
                      if (resp.ecommPaymentResponseList) {
                        this.paymentResponseData = resp.ecommPaymentResponseList;
                        resp.ecommPaymentResponseList.forEach((element, ind) => {
                          if (ind == 0) {
                            this.txnData.responseCode = element.responseCode;
                            this.txnData.responseMessage = element.responseMessage;
                            this.txnData.referenceOrderId = element.referenceOrderId;
                            this.txnData.recurrentToken = element.recurrentToken;
                            if (element.responseCode === ResponseCode.successResponseCode) {
                              if (element.htmlRequest != undefined) {
                                this.encRequest = this.stringToHTML(resp.ecommPaymentResponseList[0].htmlRequest);
                                this.actionUrl = this.stringToAction(resp.ecommPaymentResponseList[0].htmlRequest);
                                this.headerService.changeLoaderStatus(true);
                                this.simulatorPageCall(this.encRequest);
                              }
                              if (element.htmlRequest == undefined && resp.ecommPaymentResponseList[0].responseCode === ResponseCode.successResponseCode) {
                                this.txnStatus = true;
                                setTimeout(() => {
                                  this.loadingFalse();
                                }, 4500);
                                this.showTxnComplete();
                                return true;
                              }
                              else {
                                this.txnStatus = false;
                                return;
                              }
                            }
                          }
                        });
                      }
                    });
                } else {
                  this.lightboxdataService.proceedToPay(cardValue, this.cardExpiry, this.nameOnCard,
                    cvvValue, this.saveCard, this.additionalData, this.sessionData.sessionId, this.lightBoxData,
                    'SDK', this.emiPayload, this.splitTxnInfo, this.loyaltyFlag, this.fallback).subscribe(resp => {
                      if (resp.ecommPaymentResponseList) {
                        this.paymentResponseData = resp.ecommPaymentResponseList;
                        resp.ecommPaymentResponseList.forEach((element, ind) => {
                          if (ind == 0) {
                            this.txnData.responseCode = element.responseCode;
                            this.txnData.responseMessage = element.responseMessage;
                            this.txnData.referenceOrderId = element.referenceOrderId;
                            this.txnData.recurrentToken = element.recurrentToken;
                            if (element.responseCode === ResponseCode.successResponseCode) {
                              if (element.htmlRequest != undefined) {
                                this.encRequest = this.stringToHTML(resp.ecommPaymentResponseList[0].htmlRequest);
                                this.actionUrl = this.stringToAction(resp.ecommPaymentResponseList[0].htmlRequest);
                                this.headerService.changeLoaderStatus(true);
                                this.simulatorPageCall(this.encRequest);
                              }
                              if (element.htmlRequest == undefined && resp.ecommPaymentResponseList[0].responseCode === ResponseCode.successResponseCode) {
                                setTimeout(() => {
                                  this.loadingFalse();
                                }, 4000);
                                this.txnStatus = true;
                                this.showTxnComplete();
                                return true;
                              }
                              else {
                                this.txnStatus = false;
                                return;
                              }
                            }
                          }
                        });
                      }
                    });
                }
              }
            }
            else {
              this.deleteSavedCardOnCancelOTP('OnCancelOtp');
            }
          } else {
            if (this.isServerDown != 'Yes') {
              if (this.fallback === 'Y') {
                this.txnStatus = false;
                return;
              }
              this.fallback = 'Y';
              if (this.fallback === 'Y') {
                if (this.cardTypeFromGlobalScope === 'saved') {
                  this.additionalData = JSON.stringify({ electronicGoodsIndicator: 'D', invoiceId: this.paymentInfo.id });
                  this.cnpdataService.proceedToPayToken(this.savedCardCVV, this.cardTokenList[this.selectedCard].token,
                    this.additionalData, this.sessionData.sessionId, this.paymentInfo, this.hierarchyData, this.emiPayload, this.fallback).subscribe(resp => {
                      if (resp.ecommPaymentResponseList) {
                        this.paymentResponseData = resp.ecommPaymentResponseList;
                        resp.ecommPaymentResponseList.forEach((element, ind) => {
                          if (ind == 0) {
                            this.txnData.responseCode = element.responseCode;
                            this.txnData.responseMessage = element.responseMessage;
                            this.txnData.referenceOrderId = element.referenceOrderId;
                            this.txnData.recurrentToken = element.recurrentToken;
                            if (element.responseCode === ResponseCode.successResponseCode) {
                              if (element.htmlRequest != undefined) {
                                this.encRequest = this.stringToHTML(resp.ecommPaymentResponseList[0].htmlRequest);
                                this.actionUrl = this.stringToAction(resp.ecommPaymentResponseList[0].htmlRequest);
                                this.headerService.changeLoaderStatus(true);
                                this.simulatorPageCall(this.encRequest);
                              }
                              if (element.htmlRequest == undefined && resp.ecommPaymentResponseList[0].responseCode === ResponseCode.successResponseCode) {
                                this.cnpdataService.setpaidStatus(this.paymentInfo.id).subscribe(resp => {
                                  this.txnStatus = true;
                                });
                              }
                            }
                          }
                        });
                      }
                      setTimeout(() => {
                        this.loadingFalse();
                      }, 4500);
                      this.showTxnComplete();
                    }, err => {
                      this.txnStatus = false;
                      this.showTxnComplete();
                    });
                } else {
                  const cvvValue = this.cvvFieldValue;
                  const re = /[\s-\s]/gi;
                  const cardValue = this.cardNumber.replace(re, '');
                  if (this.addCardCheck) {
                    this.saveCard = 'on';
                  }
                  this.additionalData = JSON.stringify({ electronicGoodsIndicator: 'D', invoiceId: this.paymentInfo.id });
                  this.cnpdataService.proceedToPay(cardValue, this.cardExpiry, this.nameOnCard, cvvValue, this.saveCard,
                    this.additionalData, this.sessionData.sessionId, this.paymentInfo, this.hierarchyData, this.emiPayload, this.fallback).subscribe(resp => {
                      if (resp.ecommPaymentResponseList) {
                        this.paymentResponseData = resp.ecommPaymentResponseList;
                        resp.ecommPaymentResponseList.forEach((element, ind) => {
                          if (ind == 0) {
                            this.txnData.responseCode = element.responseCode;
                            this.txnData.responseMessage = element.responseMessage;
                            this.txnData.referenceOrderId = element.referenceOrderId;
                            this.txnData.recurrentToken = element.recurrentToken;
                            if (element.responseCode === ResponseCode.successResponseCode) {
                              if (element.htmlRequest != undefined) {
                                this.encRequest = this.stringToHTML(resp.ecommPaymentResponseList[0].htmlRequest);
                                this.actionUrl = this.stringToAction(resp.ecommPaymentResponseList[0].htmlRequest);
                                this.headerService.changeLoaderStatus(true);
                                this.simulatorPageCall(this.encRequest);
                              }
                              if (element.htmlRequest == undefined && resp.ecommPaymentResponseList[0].responseCode === ResponseCode.successResponseCode) {
                                this.cnpdataService.setpaidStatus(this.paymentInfo.id).subscribe(resp => {
                                  this.txnStatus = true;
                                  setTimeout(() => {
                                    this.loadingFalse();
                                  }, 4000);
                                });
                              }
                            }
                          }
                        });
                      } else {
                        return;
                      }
                    });
                }
              }
            }
            else {
              this.txnStatus = false;
              this.deleteSavedCardOnCancelOTP('OnCancelOtp');
            }
          }
        }, 2600);
      });
  }
  callBackResponseBank() {
    this.lightboxdataService.otpTransStatus(this.paymentResponseData[0].transactionId).subscribe(resp => {
      if (resp != null) {
        if (resp.ecommPaymentResponseList[0].responseCode === ResponseCode.successResponseCode) {
          this.txnStatus = true;
          this.headerService.changeLoaderStatus(false);
        } else {
          this.headerService.changeLoaderStatus(false);
          this.deleteSavedCardOnCancelOTP('OnCancelOtp');
        }
        this.headerService.changeLoaderStatus(false);
      }

      if (resp == null) {
        this.deleteSavedCardOnCancelOTP('OnCancelOtp');
        let count = parseInt(environment.retryCount);
        for (let i = 1; i <= count; i++) {
          setTimeout(() => {
            this.lightboxdataService.otpTransStatus(this.paymentResponseData[0].transactionId).subscribe(resp => {
              if (i == count && resp == null) {
                this.updateTransactionStatus();
              }
              return;
            });
          }, 2000);
        }
        this.headerService.changeLoaderStatus(false);
      }
    },
      err => {
        this.deleteSavedCardOnCancelOTP('OnCancelOtp');
        if (err.status === 0 || err.status === 500 || err.status === 400) {
          let count = parseInt(environment.retryCount);
          for (var i = 0; i < count; i++) {
            setTimeout(() => {
              this.lightboxdataService.otpTransStatus(this.paymentResponseData[0].transactionId).subscribe(resp => {
                return;
              });
            }, 1000);
          }
        }
      });
    this.headerService.changeLoaderStatus(false);
  }

  updateTransactionStatus() {
    let updatetransactionID = this.paymentResponseData[0].transactionId;
    this.lightboxdataService.updateTransStatus(updatetransactionID).subscribe(resp => {
    })
  }

  deleteSavedCard(): void {
    if (this.initiateContextPathExists) {
      this.deleteSavedCardForWeb();
      return
    }
    this.lightboxdataService.deleteSavedCard(this.cardTokenList[this.selectedCard].token).subscribe(resp => {
      if (resp.responseCode === ResponseCode.successResponseCode) {
        if (this.lightboxEnable) {
          this.getCardList();
        } else {
          this.gotoHome();
        }
      }
    });
  }

  deleteSavedCardForWeb() {
    const payload: IDeleteCardPayload = {
      tokenId: this.cardTokenList[this.selectedCard].tokenId,
      paymentChannelId: this.lightboxEnable ? this.lightBoxData.paymentChannelId : this.hierarchyData.paymentChannelId,
      hierLabelIdReference: this.lightboxEnable ? this.lightBoxData.hierLabelIdReference : this.hierarchyData.hierLabelIdReference,
    }
    this.lightboxdataService.deleteSavedCardForWeb(payload).subscribe(resp => {
      if (resp.responseCode === ResponseCode.successResponseCode) {
        if (this.lightboxEnable) {
          this.getCardList();
        } else {
          this.gotoHome();
        }
      }
    });
  }

  deleteSavedCardOnCancelOTP(getCallBackOnOtp): void {
    this.lightboxdataService.deleteSavedCard(this.callBackOnCancelOTP).subscribe(resp => {
      if (getCallBackOnOtp == 'OnCancelOtp') {
        if (resp.responseCode === ResponseCode.successResponseCode) {
          if (this.lightboxEnable) {
            return;
          } else {
            return;
          }
        }
      }
    });
  }

  handleCardKeyDown(event: KeyboardEvent, value: string, nextField: HTMLInputElement): void {
    if (event.key !== ' ' && !isNaN(Number(event.key)) && value.length === 4) {
      const element = event.target as HTMLInputElement;
      if (element.selectionStart === element.selectionEnd) {
        nextField.value = event.key;
      }
    }
  }

  handleCardKeyUp(event: KeyboardEvent, value: string, previousField: HTMLInputElement, nextField: HTMLInputElement): void {
    this.checkCardValidity(false);
    if (event.key === 'Backspace' && !value.length && previousField) {
      previousField.focus();
      return;
    }
    if (value.length === 4 && nextField && !isNaN(Number(event.key))) {
      nextField.focus();
      return;
    }
  }

  handleExpiryKeyDown(event: KeyboardEvent, value: string, nextField: HTMLInputElement): void {
    if (event.key !== ' ' && !isNaN(Number(event.key)) && value.length === 2) {
      const element = event.target as HTMLInputElement;
      if (element.selectionStart === element.selectionEnd) {
        nextField.value = event.key;
      }
    }
  }

  handleExpiryKeyUp(event: KeyboardEvent, value: string, previousField: HTMLInputElement, nextField: HTMLInputElement): void {
    this.isExpDateValid();
    if (event.key === 'Backspace' && !value.length && previousField) {
      previousField.focus();
      return;
    }
    if (value.length === 2 && nextField && !isNaN(Number(event.key))) {
      nextField.focus();
      return;
    }
  }

  checkBinEligibility(): void {
    if (this.initiateContextPathExists) {
      this.checkBinEligibilityForWeb();
      return;
    }
    if (this.lightboxEnable) {
      this.emiPaymentsService.fetchBinEligibility(
        this.lightBoxData.hierLabelIdReference,
        this.lightBoxData.transactionAmount,
        this.lightBoxData.paymentChannelId,
        this.cardNumber.substring(0, 8),
      ).subscribe(resp => {
        if (resp.responseCode === ResponseCode.emiEligibility) {
          if (resp.emiEligibility) {
            this.fetchedBankId = resp.bankId;
            this.CardNumError = '';
          } else {
            this.CardNumError = this.translatedMsgs['checkoutModule.emiCardNA'];
          }
        }
      }, error => this.handleError(error));
    } else {
      this.emiPaymentsService.fetchBinEligibility(
        this.hierarchyData.hierLabelIdReference,
        this.paymentInfo.amount.toString(),
        this.hierarchyData.paymentChannelId,
        this.cardNumber.substring(0, 8)
      ).subscribe(resp => {
        if (resp.responseCode === ResponseCode.emiEligibility) {
          if (resp.emiEligibility) {
            this.fetchedBankId = resp.bankId;
            this.CardNumError = '';
          } else {
            this.CardNumError = this.translatedMsgs['checkoutModule.emiCardNA'];
          }
        }
      }, error => this.handleError(error));
    }
  }

  checkBinEligibilityForWeb() {
    if (this.lightboxEnable) {

      const binPayload: IEMIEligibilityPayload = {
        hierLabelIdReference: this.lightBoxData.hierLabelIdReference,
        amount: this.lightBoxData.transactionAmount,
        paymentChannelId: this.lightBoxData.paymentChannelId,
        bin: this.cardNumber.substring(0, 8),
        processorCheck: true,
        currency: this.lightBoxData.currency,
      };

      this.emiPaymentsService.fetchBinEligibilityForWeb(binPayload).subscribe(resp => {
        if (resp.responseCode === ResponseCode.emiEligibility) {
          if (resp.emiEligibility) {
            this.fetchedBankId = resp.bankId;
            this.processorName = resp.processorName;
            this.CardNumError = '';
            this.srRuleId = resp.srRuleId;
            this.srMid = resp.srMid;
            this.fetchedBankName = resp.bankName;
            this.fetchedBinNumber = resp.binNumber;
            this.fetchedCardCountry = resp.cardCountry;
            this.fetchedCardScheme = resp.cardScheme;
            this.fetchedCardType = resp.cardType;
          } else {
            this.CardNumError = this.translatedMsgs['checkoutModule.emiCardNA'];
          }
        }
      }, error => this.handleError(error));
    } else {

      const binPayload: IEMIEligibilityPayload = {
        hierLabelIdReference: this.hierarchyData.hierLabelIdReference,
        amount: this.paymentInfo.amount.toString(),
        paymentChannelId: this.hierarchyData.paymentChannelId,
        bin: this.cardNumber.substring(0, 8),
        processorCheck: true,
        currency: this.paymentInfo.currencyCode,
      };

      this.emiPaymentsService.fetchBinEligibilityForWeb(binPayload).subscribe(resp => {
        if (resp.responseCode === ResponseCode.emiEligibility) {
          if (resp.emiEligibility) {
            this.fetchedBankId = resp.bankId;
            this.processorName = resp.processorName;
            this.CardNumError = '';
          } else {
            this.CardNumError = this.translatedMsgs['checkoutModule.emiCardNA'];
          }
        }
      }, error => this.handleError(error));
    }
  }

  checkCardValidity(blurFactor: boolean): void {
    const cardNumber = this.cardAValue + this.cardBValue + this.cardCValue + this.cardDValue;
    if (cardNumber === this.cardNumber) {
      return;
    }
    this.fetchedBankId = '';
    this.CardNumError = '';
    this.cardNumber = cardNumber;
    const typeAndValidity = this.getCardTypeAndValidity(this.cardNumber);
    this.cardImage = typeAndValidity.type;
    this.cardLogo = typeAndValidity.logo;
    this.cardValid = typeAndValidity.validity;
    if (!this.cardValid) {
      this.CardNumError = this.translatedMsgs['checkoutModule.cardnuminvalid'];
    } else {
      if (this.cardImage === '') {
        this.CardNumError = this.translatedMsgs['checkoutModule.cardnotsupported'];
      } else {
        this.CardNumError = '';
        if (this.isEmiScreen && this.cardNumber.length > 8 && !blurFactor) {
          this.checkBinEligibility();
        }
      }
    }
  }

  handlePaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  getCardTypeAndValidity(cardNumber: string, cardScheme?: string): { type: string, validity: boolean, logo: string } {
    const re = /[\s-\s]/gi;
    const cardValue = cardNumber.replace(re, '');
    const typeAndValidty: { type: string, logo: string, validity: boolean } = {
      type: '',
      logo: '',
      validity: false
    };

    for (const card in this.regexps) {
      if (cardValue.match(this.regexps[card].regex) || (cardScheme && cardScheme === card)) {
        typeAndValidty.type = card;
        const logoUrl = this.regexps[card].logo;
        const url = new URL(logoUrl);
        const pathname = url.pathname;
        const filenameMatch = pathname.match(/\/([^\/?]+)$/);
        const filename = filenameMatch ? filenameMatch[1] : null;
        const cleanedFilename = filename ? filename.replace(/^paymentInstrument_/i, '').replace(/\.action$/i, '') : null;
        typeAndValidty.logo = this.loadLogoPath(cleanedFilename);
        break;
      }
    }

    typeAndValidty.validity = this.isCardValid(cardNumber);
    return typeAndValidty;
  }

  loadLogoPath(cleanedFilename: string): string {
    const basePath = 'assets/images/';
    const logoPath = `${basePath}${cleanedFilename}.png`;
    return logoPath;
  }

  isCardValid(value: string): boolean {
    if (/[^0-9-\s]+/.test(value) || value.length < 13 || value.length > 19) {
      return false;
    }
    let nCheck = 0;
    let bEven = false;
    value = value.replace(/\D/g, '');
    for (let n = value.length - 1; n >= 0; n--) {
      const cDigit = value.charAt(n);
      let nDigit = parseInt(cDigit, 10);
      if (bEven && (nDigit *= 2) > 9) {
        nDigit -= 9;
      }
      nCheck += nDigit;
      bEven = !bEven;
    }
    return (nCheck % 10) === 0;
  }

  isExpDateValid(): void {
    if (!this.monthFieldValue || !this.yearFieldValue) {
      this.expDateValid = true;
      return;
    } else {
      const today = new Date();
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();

      const month = Number(this.monthFieldValue);
      const year = Number(this.yearFieldValue);
      if (year >= currentYear) {
        if ((month < 1 || month > 12) || (year === currentYear && month < currentMonth + 1)) {
          this.expDateValid = false;
          return;
        } else {
          this.expDateValid = true;
          return;
        }
      }
      this.expDateValid = false;
    }
  }

  isCVVValid(): void {
    if (!this.cvvFieldValue) {
      this.cvvValid = false;
    }
    this.cvvValid = this.cvvFieldValue && (this.cvvFieldValue.length === 3 || this.cvvFieldValue.length === 4);
  }

  cvvTipShow() {
    this.cvvHelpToolTip = true;
  }

  cvvTipHide() {
    this.cvvHelpToolTip = false;
  }

  isFormValid() {
    if (this.cardValid && this.cardImage !== '' && this.expDateValid && this.cvvValid) {
      if (this.isEmiScreen && !this.fetchedBankId) {
        return false;
      }
      return true;
    }
    return false;
  }

  selectCard(index: number, enabled: boolean) {
    if (this.isEmiScreen && enabled == false) {
      this.selectedCard = '';
    }
    else if (enabled == true) {
      this.selectedCard = index.toString();
    }
    else {
      this.selectedCard = index.toString();
    }
    this.savedCardCVV = '';
  }

  navToAddForm() {
    this.currentScreen = 'home';
    this.selectedCard = '';
    this.savedCardCVV = '';
  }

  isSavedFormValid() {
    const index = Number(this.selectedCard);
    if (index >= 0 && index < this.cards.length
      && !isNaN(Number(this.savedCardCVV)) && this.savedCardCVV.length >= 3 && this.savedCardCVV.length <= 4) {
      return true;
    } else {
      return false;
    }
  }

  paymentSubmit() {
    setTimeout(() => {
    }, 2000);
  }

  getCardList() {
    if (this.initiateContextPathExists) {
      this.getCardListForWeb();
      return;
    }
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const combineMonYear = `${this.monthFieldValue.toString()}-${currentYear % 100}`;
    this.lightboxdataService.getCardList(
      'SDK', this.sessionData.sessionId, this.lightBoxData.paymentChannelId,
      this.lightBoxData.hierLabelIdReference, this.lightBoxData.mobilePrefix,
      this.lightBoxData.mobile, this.lightBoxData.email,
      this.lightBoxData.hierarchyCriteria, this.lightBoxData.customerCode,
      this.lightBoxData.transactionAmount, this.isEmiScreen
    ).subscribe(resp => {
      if ((resp.responseCode === ResponseCode.successResponseCode || resp.responseCode === OtpResponseConstants.confirmedOtp) &&
        resp.cardTokensList) {
        this.cardTokenList = resp.cardTokensList || [];
        if (this.cardTokenList.length) {
          this.cards = [];
          this.isSaveCardsExist = true;
          for (const cardToken of this.cardTokenList) {
            if (this.isEmiScreen) {
              this.cards.push({
                cardNumber: cardToken.token.substring(0, 6) + cardToken.token.substring(42, 46),
                enabled: cardToken.emiAllowed
              });
            } else {
              if (cardToken.expiryDate < `${combineMonYear}`) {
                this.cards.push({
                  cardNumber: cardToken.token.substring(0, 6) + cardToken.token.substring(42, 46),
                  enabled: false
                });
              }
              else {
                this.cards.push({
                  cardNumber: cardToken.token.substring(0, 6) + cardToken.token.substring(42, 46),
                  enabled: true
                });
              }
            }
          }
          this.currentScreen = this.CardScreens.SAVEDCARDS;
        } else {
          this.isSaveCardsExist = false;
          this.currentScreen = this.CardScreens.HOME;
          setTimeout(() => this.cardAField.nativeElement.focus(), 0);
        }
      } else {
        this.handleError();
      }
    }, error => this.handleError(error));
  }

  getCardListForWeb() {
    if (this.lightboxEnable) {
      this.loadingTrue();
    }
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const fetchCardPayload: IFetchCardPayload = {
      inboundFrom: 'WEB',
      sessionId: this.sessionData.sessionId,
      paymentChannelId: this.lightboxEnable ? this.lightBoxData.paymentChannelId : this.hierarchyData.paymentChannelId,
      hierLabelIdReference: this.lightboxEnable ? this.lightBoxData.hierLabelIdReference : this.hierarchyData.hierLabelIdReference,
      mobilePrefix: this.lightboxEnable ? this.lightBoxData.mobilePrefix : this.paymentInfo.mobilePreFix,
      mobileNumber: this.lightboxEnable ? this.lightBoxData.mobile : this.paymentInfo.mobileNumber,
      emailAddress: this.lightboxEnable ? this.lightBoxData.email : this.paymentInfo.mailId,
      hierarchyCriteria: this.lightboxEnable ? this.lightBoxData.hierarchyCriteria : this.hierarchyData.hierarchyCriteria,
      customerCode: this.lightboxEnable ? this.lightBoxData.mobile : this.paymentInfo.mobileNumber,
      transactionAmount: this.lightboxEnable ? this.lightBoxData.transactionAmount : this.paymentInfo.amount.toString(),
      emiCheck: this.isEmiScreen
    }

    if (this.isEmiScreen) {
      fetchCardPayload.amount = this.lightboxEnable ? this.lightBoxData.transactionAmount : this.paymentInfo.amount.toString();
      fetchCardPayload.processorCheck = true;
      fetchCardPayload.currency = this.lightboxEnable ? this.lightBoxData.currency : this.paymentInfo.currencyCode;
    }

    this.lightboxdataService.getCardListForWeb(fetchCardPayload).subscribe(resp => {
      if ((resp.responseCode === ResponseCode.successResponseCode || resp.responseCode === OtpResponseConstants.confirmedOtp)) {
        this.cardTokenList = resp.cardTokensList;
        if (this.cardTokenList && this.cardTokenList.length) {
          this.cards = [];
          this.isSaveCardsExist = true;
          for (const cardToken of this.cardTokenList) {
            var month = Number(cardToken.cardExpMonth);
            var year = Number(cardToken.cardExpYear)
            var cardExpiryDate = new Date(year, month, 0, 23, 59, 59)

            var today = new Date();
            var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59);
            var isCardExpired = cardExpiryDate < lastDayOfMonth

            if (this.isEmiScreen) {
              this.cards.push({
                cardNumber: cardToken.maskedPan,
                enabled: cardToken.emiAllowed,
                cardScheme: cardToken.cardScheme,
                isCardExpired: isCardExpired
              });
            } else {
              this.cards.push({
                cardNumber: cardToken.maskedPan,
                enabled: true,
                cardScheme: cardToken.cardScheme,
                isCardExpired: isCardExpired
              });
            }
          }
          this.currentScreen = this.CardScreens.SAVEDCARDS;

          if (!this.lightboxEnable) {
            this.otpVerified = true;
            this.otpSent = false;
            this.exhausted = false;
          }
        } else {
          this.isSaveCardsExist = false;
          this.currentScreen = this.CardScreens.HOME;
          setTimeout(() => this.cardAField.nativeElement.focus(), 0);
        }
        this.loadingFalse();
      } else {
        this.handleError();
      }
    }, error => this.handleError(error));
  }

  getPreAuthMsg() {
    if (this.lightBoxData.transactionType === fieldnames.preAuth) {
      this.lightboxdataService.getPreAuthMessage(this.lightBoxData.hierLabelIdReference).subscribe(resp => {
        if (resp.responseCode === ResponseCode.successResponseCode) {
          this.preAuthData = resp.preAuthDelayReq.message;
          this.preauthShortMsg = this.preAuthData.substring(0, 38);
        }
      }, error => this.handleError(error));
    }
  }

  onInputEntry(event, nextInput, field) {
    const input = event.target;
    const length = input.value.length;
    if (length >= 1 && nextInput) {
      nextInput.focus();
    }
    setTimeout(() => {
      this[field] = input.value.substring(0, 1);
    }, 0);
  }

  resendOtp(): void {
    if (!this.resent) {
      this.resent = true;
      this.sendOtp();
    }
  }

  verifyOtp() {
    this.otp = this.otp1.toString() + this.otp2.toString() + this.otp3.toString() + this.otp4.toString();
    this.cnpdataService.verifyOtp(
      this.otp, this.sessionData.sessionId, this.hierarchyData.paymentChannelId,
      this.hierarchyData.hierLabelIdReference, this.paymentInfo.mobilePreFix,
      this.paymentInfo.mobileNumber, this.paymentInfo.mailId,
      this.hierarchyData.hierarchyCriteria, this.paymentInfo.amount.toString(),
      this.isEmiScreen
    ).subscribe(resp => {
      if (resp.responseCode === OtpResponseConstants.otpConfirmed) {
        if (this.initiateContextPathExists) {
          this.getCardListForWeb();
        } else {
          this.cardTokenList = resp.cardTokensList;
          this.isSaveCardsExist = true;
          for (let i = 0; i < this.cardTokenList.length; i++) {
            const imgName = this.getCardTypeAndValidity(this.cardTokenList[i].token.substring(0, 6) +
              this.cardTokenList[0].token.substring(42, 46)).type;
            this.cardTokenList[i].imageName = imgName;
          }
          this.otpVerified = false;
          this.otpSent = false;
          this.exhausted = false;
          this.isSaveCardsExist = true;
          this.currentScreen = this.CardScreens.SAVEDCARDS;
          for (const cardToken of this.cardTokenList) {
            if (this.isEmiScreen) {
              this.cards.push({
                cardNumber: cardToken.token.substring(0, 6) + cardToken.token.substring(42, 46),
                enabled: cardToken.emiAllowed
              });
            } else {
              this.cards.push({
                cardNumber: cardToken.token.substring(0, 6) + cardToken.token.substring(42, 46),
                enabled: true
              });
            }
          }
        }
      } else if (resp.responseCode === OtpResponseConstants.confirmedOtp) {
        if (this.initiateContextPathExists) {
          this.getCardListForWeb();
        } else {
          this.otpVerified = true;
          this.otpSent = false;
          this.exhausted = false;
          this.isSaveCardsExist = false;
          this.currentScreen = this.CardScreens.HOME;
          setTimeout(() => this.cardAField.nativeElement.focus(), 0);
        }
      } else {
        this.otpSent = true;
        this.successMessage = resp.responseMessage;
        this.otpVerified = false;
        if (resp.responseCode === OtpResponseConstants.otpRetryExceeded) {
          this.exhausted = true;
          setTimeout(() => {
            this.gotoHome();
          }, 5000);
        } else {
          this.exhausted = false;
        }
      }
    }, error => this.handleError(error));
    this.otp1 = null;
    this.otp2 = null;
    this.otp3 = null;
    this.otp4 = null;
    setTimeout(() => this.otp1Field.nativeElement.focus(), 0);
  }

  navigatebacktopaymantpage() {
    this.addCardCheck = false;
    this.otpSent = false;
    this.otpVerified = true;
    this.showretrypage = false;
    if (this.isSaveCardsExist) {
      this.currentScreen = this.CardScreens.SAVEDCARDS;
    } else {
      this.currentScreen = this.CardScreens.HOME;
    }

  }

  handleError(error?: any): void {
    if (this.initiateContextPathExists) {
      this.txnStatus = false;
      this.showTxnComplete();
      return;
    }
    if (error) {
      console.error(error);
    }
    this.router.navigate(['error']);
  }
}