import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ErrorTypes, HeaderService, ILightBoxData, PageTypes, UserdataService } from 'src/app/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  pageType = '';
  PageTypes = PageTypes;
  lightboxInfo: ILightBoxData;
  type: string;
  ErrorTypes = ErrorTypes;

  constructor(
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private router: Router,
    private userDataService: UserdataService
  ) {
    this.pageType = this.headerService.getPageType();
    this.type = this.route.snapshot.data.type;
    this.headerService.changeHeaderStatus(false);
    this.headerService.changeFooterStatus(true);
    this.headerService.changeLoaderStatus(false);
  }

  ngOnInit() {
  }

  retry(): void {
    this.router.navigateByUrl(this.headerService.getRetryUrl());
  }

  close(): void {
    this.lightboxInfo = this.userDataService.getLightboxInfo();
    const appendString = 'responseCode=4183&responseMessage=Transaction Terminated';
    this.headerService.closeModal(this.lightboxInfo.callbackurl, appendString);
  }

}
