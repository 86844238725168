export const OtpResponseConstants = {
  successOtpSent: '0024',
  otpSendSuccess: '9924',
  otpConfirmed: '0000',
  confirmedOtp: '0001',
  invalidOtp: '3003',
  otpRetryExceeded: '3004',
  otpExpired: '3005',
  error: '9999',
  otpResendLimitExceeded: '3001'
};

export const ResponseCode = {
  successResponseCode: '0000',
  invaldPaymentchChannelID: '7711',
  walletSuccess: '0002',
  emiEligibility: '9006',
  emiPlans: '9008',
  loyaltySuccess: '0011',
  loyaltySaleSuccess: '0021',
  expiredResponseCode: '8888',
  pendingResponseCode: '1111',
  failureResponseCode: '9999'
};

export const ResponseContextPath = {
  initiatePayment: '/ecom/initiatePayment'
};
