import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class CommonDataService {

  constructor(private apiService: ApiService) { }


  fetchMobilePrefixes() {
    return this.apiService.post(environment.api_mpg_url + '/fetchMobilePrefixes', { "serialId": 0, "isAllRecords": true })
      .pipe(map(data => { return data }));
  }
  fetchMerchantPaymentRoles(transactingHid: any, transactingMid: any, transactingTid: any, currencyCode: string, hierarchyCriteria: string, paymentInstrument: string) {
    let bodyData = { transactingHid: transactingHid, transactingMid: transactingMid, transactingTid: transactingTid, hierarchyCriteria: hierarchyCriteria, currencyCode: currencyCode, paymentInstrument: paymentInstrument }
    // Add Cache-Control header
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this.apiService.post(environment.api_mpg_url + '/fetchMerchantPaymentRoles', bodyData)
      .pipe(map(data => { return data }));
  }
  authorize(paymentChannelId: string, hierLabelIdReference: string, checkSum: string, hierarchyCriteria: string) {
    let dataBody = { paymentChannelId: paymentChannelId, hierLabelIdReference: hierLabelIdReference, checkSum: checkSum, hierarchyCriteria: hierarchyCriteria };
    return this.apiService.post(environment.api_mpg_url + '/authorize', dataBody)
      .pipe(map(data => { return data }));
  }
  fetchCurrencyDetails(curCode: string) {
    return this.apiService.post(environment.api_mpg_url + '/fetchCurrencyDetails', { transactionCurrencyCode: curCode })
      .pipe(map(data => { return data }));
  }


}