export const publicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDlOJu6TyygqxfWT7eLtGDwajtN
FOb9I5XRb6khyfD1Yt3YiCgQWMNW649887VGJiGr/L5i2osbl8C9+WJTeucF+S76
xFxdU6jE0NQ+Z+zEdhUTooNRaY5nZiu5PgDB0ED/ZKBUSLKL7eibMxZtMlUDHjm4
gwQco1KRMDSmXSMkDwIDAQAB
-----END PUBLIC KEY-----`;
export const privateKey = `-----BEGIN RSA PRIVATE KEY-----
MIICXQIBAAKBgQDlOJu6TyygqxfWT7eLtGDwajtNFOb9I5XRb6khyfD1Yt3YiCgQ
WMNW649887VGJiGr/L5i2osbl8C9+WJTeucF+S76xFxdU6jE0NQ+Z+zEdhUTooNR
aY5nZiu5PgDB0ED/ZKBUSLKL7eibMxZtMlUDHjm4gwQco1KRMDSmXSMkDwIDAQAB
AoGAfY9LpnuWK5Bs50UVep5c93SJdUi82u7yMx4iHFMc/Z2hfenfYEzu+57fI4fv
xTQ//5DbzRR/XKb8ulNv6+CHyPF31xk7YOBfkGI8qjLoq06V+FyBfDSwL8KbLyeH
m7KUZnLNQbk8yGLzB3iYKkRHlmUanQGaNMIJziWOkN+N9dECQQD0ONYRNZeuM8zd
8XJTSdcIX4a3gy3GGCJxOzv16XHxD03GW6UNLmfPwenKu+cdrQeaqEixrCejXdAF
z/7+BSMpAkEA8EaSOeP5Xr3ZrbiKzi6TGMwHMvC7HdJxaBJbVRfApFrE0/mPwmP5
rN7QwjrMY+0+AbXcm8mRQyQ1+IGEembsdwJBAN6az8Rv7QnD/YBvi52POIlRSSIM
V7SwWvSK4WSMnGb1ZBbhgdg57DXaspcwHsFV7hByQ5BvMtIduHcT14ECfcECQATe
aTgjFnqE/lQ22Rk0eGaYO80cc643BXVGafNfd9fcvwBMnk0iGX0XRsOozVt5Azil
psLBYuApa66NcVHJpCECQQDTjI2AQhFc1yRnCU/YgDnSpJVm1nASoRUnU8Jfm3Oz
uku7JUXcVpt08DFSceCEX9unCuMcT72rAQlLpdZir876
-----END RSA PRIVATE KEY-----`;

export const certificate = `-----BEGIN CERTIFICATE-----
MIIDyTCCArGgAwIBAgIEAZgbDTANBgkqhkiG9w0BAQsFADCBlDELMAkGA1UEBhMCSU4xEjAQBgNV
BAgTCUthcm5hdGFrYTESMBAGA1UEBxMJQmVuZ2FsdXJ1MSEwHwYDVQQKExhDb212aXZhIFRlY2hu
b2xvZ2llcyBMdGQxIzAhBgNVBAsTGk1vYmlsZSBGaW5hbmNpYWwgU29sdXRpb25zMRUwEwYDVQQD
EwxwYXlQTFVTIENPUkUwHhcNMTcxMTAzMDUwNDQ3WhcNMTgwMjAxMDUwNDQ3WjCBlDELMAkGA1UE
BhMCSU4xEjAQBgNVBAgTCUthcm5hdGFrYTESMBAGA1UEBxMJQmVuZ2FsdXJ1MSEwHwYDVQQKExhD
b212aXZhIFRlY2hub2xvZ2llcyBMdGQxIzAhBgNVBAsTGk1vYmlsZSBGaW5hbmNpYWwgU29sdXRp
b25zMRUwEwYDVQQDEwxwYXlQTFVTIENPUkUwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIB
AQC3q9u7jyH17zRpoGhYhXJfJhij/LWPsNsED+1tTfq+LogKXnCTa6t+uxoVM2uOWF4ab+KcO1Re
CqoWK785DQFNATEGJq9NwQR8V6FtLwEP9jaDQfpzt2MSmGXvntMc9nfSjpZaVLxRYSIQNVtH/w/3
b+DcD30myK3PhTUaw3W2w5LhMWrQJFsKRR5PULutgD0WeaZ8AU8inQtf8sI6eBAetgsZUoJnG+uv
tLLTzeI/uycHJT2JZCgUdBz7n0S96cbTkZdxnTh2kXbYKegFOd6RdaldZY48HmALznzbb9cGMvf9
9pmm2txUrmpZsTQlye4q1Eolyrq542WP0i8gKNb5AgMBAAGjITAfMB0GA1UdDgQWBBRJ+DZYHfSu
q0yiqp4UnYBjjOl4EDANBgkqhkiG9w0BAQsFAAOCAQEASO642VVVfVSTKXA6ojwR6FIWDYHCHNyC
ZKf/t8EWtr2aFPTWu2zY+USKSfo7fKlZDxLdfNNqNAVDXqNhBvQLMZNci+1nlWQPqq9CRBvMBJ8/
KT30GkKpqkjzRd56cBp+2RN3pqIbETgKixjqXpoAoZ6fX94OdtreiKzM32VsaEZiUPCleMtMMKS+
AXGEcN4Upo+DjRqJvoMMa0woT//ntj27HV2maFbZ7ecjSRHdpE5QdIhIgPPcealfekuCGKQqzymp
XwFDP2lxCJEnyWPbxru3x7uVWZXLDMY60pmOYGCx3DRFDQhN6OF3bGgDv6+Rxq8A26J3JYEQ4ytS
p9P5+Q==
-----END CERTIFICATE-----`;

export const keySize = 128;
export const iterationCount = 1000;