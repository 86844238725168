import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-loyalty-payment',
  templateUrl: './loyalty-payment.component.html',
  styleUrls: ['./loyalty-payment.component.scss']
})
export class LoyaltyPaymentComponent implements OnInit, OnChanges {
  @Input()
  points: number;

  @Input()
  worthAmount: number;

  @Input()
  disabled = false;

  @Input()
  currency: string;

  @Input()
  transactionAmount: number;

  @Input()
  checked: boolean;

  @Output()
  inputChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  loyaltyPay: EventEmitter<any> = new EventEmitter<any>();

  showPay = false;

  constructor() {}

  ngOnInit() {
  }

  ngOnChanges(): void {
    if (!this.points || !this.worthAmount) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  }

  inputChangeEvent(): void {
    this.inputChange.emit(this.checked);
  }

  pay(): void {
    this.loyaltyPay.emit();
  }

}
